import React from "react";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { ImYoutube2 } from "react-icons/im";
import { Link } from "react-router-dom";
import "./style.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="wrapper bottom_border">
        <div className="row w-100">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="headin5_amrc col_white_amrc pt2 first-header">
              <img src="/assets/logo.png" className="img-fluid" alt="logo" />
            </div>
            <p className="mb10">
              Find and compare over 500 WAVs for sale – from specialist WAV
              dealers and private sellers across the UK.
            </p>
          </div>

          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <h5 className="headin5_amrc col_white_amrc pt2">GET STARTED</h5>
            <ul className="footer_ul_amrc">
              <li>
                {/* <Link to="/buy">Buy</Link> */}
                <a href={window.location.origin + `/buy`} onClick={(event) => event.preventDefault()} ><Link to="/buy">Buy</Link></a>
              </li>
              <li>
                {/* <Link to="/sell-your-wav-private-seller">Sell</Link> */}
                <a href={window.location.origin + `/sell-your-wav-private-seller`} onClick={(event) => event.preventDefault()} ><Link to="/sell-your-wav-private-seller">Sell</Link></a>


              </li>
              <li>
                <a href="https://hire.wavcompare.com/" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation()
                  window.location.href = "https://hire.wavcompare.com/"
                }}>Hire</a>
              </li>
              <li>
                {/* <Link to="/dealers">Dealers</Link> */}
                < a href={window.location.origin + `/dealers`} onClick={(event) => event.preventDefault()} ><Link to="/dealers">Dealers</Link></a>

              </li>
              <li>
                {/* <Link to="/help">Help & Advice</Link> */}
                < a href={window.location.origin + `/help`} onClick={(event) => event.preventDefault()} > <Link to="/help">Help & Advice</Link></a>

              </li>
              {/* <li>
                <a href="#">Useful Resources</a>
              </li> */}
            </ul>
          </div>

          {/* <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <h5 className="headin5_amrc col_white_amrc pt2">OUR BLOG</h5>
            <ul className="footer_ul_amrc">
              <li>
                <a href="#">Conversions</a>
              </li>
              <li>
                <a href="#">Ramp or Lift</a>
              </li>
              <li>
                <a href="#">Sizes & Seats</a>
              </li>
              <li>
                <a href="#">Manual VS Automatic Wheelchair</a>
              </li>
              <li>
                <a href="#">WAV Type</a>
              </li>
            </ul>
          </div> */}

          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <h5 className="headin5_amrc col_white_amrc pt2">Quick Links</h5>
            <ul className="footer_ul_amrc">
              <li>
                {/* <Link to="/help">Help and Advice</Link> */}
                < a href={window.location.origin + `/help`} onClick={(event) => event.preventDefault()} ><Link to="/help">Help and Advice</Link></a>

              </li>
              <li>
                {/* <Link to="/wheelchair-accessible-minibus">Wheelchair Minibuses</Link> */}
                < a href={window.location.origin + `/wheelchair-accessible-minibus`} onClick={(event) => event.preventDefault()} > <Link to="/wheelchair-accessible-minibus">Wheelchair Minibuses</Link></a>

              </li>
              <li>
                {/* <Link to="/dmuk">DMUK</Link> */}

                < a href={window.location.origin + `/dmuk`} onClick={(event) => event.preventDefault()} ><Link to="/dmuk">DMUK</Link></a>
              </li>
            </ul>
          </div>


          <div className="col-sm-12 col-md-12 col-lg-2">
            <h5 className="headin5_amrc col_white_amrc pt2">Stay Updated</h5>

            <ul className="footer_ul2_amrc">
              <li>
                <a href="#">
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a href="#">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a href="#">
                  <ImYoutube2 />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <p
          style={{ fontSize: "14px" }}
          className="text-left footer-bottom-p mt-4"
        >
          Copyright @2022 | Designed by{" "}
          <a
            style={{ color: "#dc562c" }}
            href="https://handyapp.co.uk/"
            target="_blank"
          >
            HandyApp
          </a>{" "}
          | Trademarks and brands are the property of their respective owners.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
