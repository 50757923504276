import React from "react";

const TradeSeller = ({ history, setMenuOpen }) => {
  return (
    <div className="seller">
      <div className="text">
        <h1>Trade Seller</h1>
        <h4>Join us and reach new customers</h4>
        <button
          onClick={() => {
            history.push("/sell-your-wav-private-seller");
            setMenuOpen(false);
          }}
        >
          Click Here
        </button>
      </div>
      <div className="img">
        <img src="/assets/private.jpg" alt="seller" className="img-fluid" />
      </div>
    </div>
  );
};

export default TradeSeller;
