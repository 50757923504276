import {
  GET_WAVS,
  GET_WAV,
  SEARCH_WAVS,
  GET_WAV_DETAILS,
  GET_MAKES,
  GET_MODELS,
} from "../constants/constants";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;

export const getMakes = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/makes/active/all`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_MAKES, payload: data.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getModels = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/models/active/all`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_MODELS, payload: data.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getWavs = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/wav/all`, {
        method: "POST",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_WAVS, payload: data.data.wavs });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const getWav = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/wav/${id}`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_WAV, payload: data.data.wav });
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const getWavBySlug = (slug, history) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/wav/by-slug/${slug}`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_WAV, payload: data.data.wav });
        return data;
      }
      // else {
      //   history.push(`/buy`);
      // }
    } catch (err) {
      console.log(err);
    }
  };
};
export const searchWavs = (
  body = "",
  make = "",
  model = "",
  mileage = "",
  size = "",
  transmission = "",
  seating = "",
  entrance = "",
  doors = "",
  year = "",
  price = "",
  keywords = [],
  type = "",
  wheelchairs = "",
  wavType = ""
) => {
  return async (dispatch) => {
    try {
      console.log(
        "price=",
        price,
        "make=",
        make,
        "model=",
        model,
        "size=",
        size,
        "seating=",
        seating,
        "mileage",
        mileage
      );

      // const mileageStartIndex = mileage.indexOf("-");
      // const mileageStart = parseInt(mileage.substring(0, mileageStartIndex));
      // const mileageEnd = parseInt(mileage.substring(mileageStartIndex + 1));

      // const mileageStartIndex = mileage.indexOf("-");
      const mileageStart = 0;
      const mileageEnd = mileage != "" ? parseInt(mileage) : "";

      console.log("mileageStart==", mileageStart, "mileageEnd==", mileageEnd);

      const bodyStr = body === "" ? "" : `body=${body}`;
      const makeStr = make === "" ? "" : `&make=${make}`;
      const modelStr = model === "" ? "" : `&models=${model}`;
      // const mileageStr = mileage === "" ? "" : `&mileage=${mileage}`;
      const sizeStr = size === "" ? "" : `&size=${size}`;
      const transmissionStr =
        transmission === "" ? "" : `&transmission=${transmission}`;
      const seatingStr = seating === "" ? "" : `&seating=${seating}`;
      const entranceStr = entrance === "" ? "" : `&entrance=${entrance}`;
      const doorsStr = doors === "" ? "" : `&doors=${doors}`;
      const yearStr = year === "" ? "" : `&year=${year}`;
      const priceStr = price === "" ? "" : `&price=${price}`;
      const wheelchairsStr =
        wheelchairs === "" ? "" : `&wheelchairs=${wheelchairs}`;
      const wavTypeStr =
        wavType === "" ? "" : `&wav_type=${wavType}`;

      const response = await fetch(
        `${url}/wav/all?${bodyStr}${makeStr}${modelStr}${sizeStr}${transmissionStr}${seatingStr}${entranceStr}${doorsStr}${yearStr}${priceStr}${wheelchairsStr}${wavTypeStr}`,
        {
          method: "POST",
          body: JSON.stringify({ features: keywords }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        // const searchArray = [
        //   body !== "" ? { name: "body", value: body } : null,
        //   make !== "" ? { name: "make", value: make } : null,
        //   model !== "" ? { name: "model", value: model } : null,
        //   mileage !== "" ? { name: "mileage", value: mileage } : null,
        //   transmission !== ""
        //     ? { name: "transmission", value: transmission }
        //     : null,
        //   seating !== "" ? { name: "seating", value: seating } : null,
        //   entrance !== "" ? { name: "entrance", value: entrance } : null,
        //   doors !== "" ? { name: "doors", value: doors } : null,
        //   year !== "" ? { name: "year", value: year } : null,
        //   price !== "" ? { name: "price", value: price } : null,
        // ];

        const searchArray = [];

        body !== "" && searchArray.push({ name: "body", value: body });
        make !== "" && searchArray.push({ name: "make", value: make });
        model !== "" && searchArray.push({ name: "model", value: model });
        mileage !== "" && searchArray.push({ name: "mileage", value: mileage });
        transmission !== "" &&
          searchArray.push({ name: "transmission", value: transmission });
        seating !== "" && searchArray.push({ name: "seating", value: seating });
        entrance !== "" &&
          searchArray.push({ name: "entrance", value: entrance });
        doors !== "" && searchArray.push({ name: "doors", value: doors });
        year !== "" && searchArray.push({ name: "year", value: year });
        price !== "" && searchArray.push({ name: "price", value: price });
        wheelchairs !== "" &&
          searchArray.push({ name: "wheelchairs", value: wheelchairs });
        wavType !== "" &&
          searchArray.push({ name: "wavType", value: wavType });

        console.log("searchArray", searchArray);

        // const adaptationFilter =
        //   type === "passengers"
        //     ? data.data.wavs &&
        //       data.data.wavs.length > 0 &&
        //       data.data.wavs.filter(
        //         (wav) => wav.wav_type !== "Drive From Wheelchair"
        //       )
        //     : type === "drivers"
        //     ? data.data.wavs &&
        //       data.data.wavs.length > 0 &&
        //       data.data.wavs.filter(
        //         (wav) => wav.wav_type === "Drive From Wheelchair"
        //       )
        //     :
        //     data.data.wavs;

        const adaptationFilter = data.data.wavs;

        console.log("data:", adaptationFilter);
        let filtered = [];
        if (mileage !== "") {
          console.log("FILTERING MILEAGE");
          filtered =
            adaptationFilter &&
            // adaptationFilter.length > 0 &&
            adaptationFilter.filter(
              (wav) =>
                parseInt(wav.mileage) >= mileageStart &&
                parseInt(wav.mileage) <= mileageEnd
            );
          console.log("filtered aa:", filtered);
        }

        let payload = filtered.length > 0 ? filtered : adaptationFilter;

        console.log("payload", payload);
        dispatch({
          type: SEARCH_WAVS,
          payload: {
            wavs: payload,
            searchArray,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const getWavDetails = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/wav/details`);
      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_WAV_DETAILS, payload: data.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
