import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "./pagination.scss";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (paginationRange && paginationRange.length && paginationRange.length > 0) {
    if (currentPage === 0 || paginationRange.length < 2) {
      return null;
    }
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage =
    paginationRange && paginationRange.length && paginationRange.length > 0
      ? paginationRange[paginationRange.length - 1]
      : 1;
  return (
    <div className="pagination-wrapper">
      <div
        className={
          currentPage === 1
            ? "pagination-chevron pagination-disabled"
            : "pagination-chevron"
        }
        onClick={onPrevious}
      >
        <FaChevronLeft color={currentPage === 1 && "#ccc"} />
      </div>
      <ul
        className={classnames("pagination-container", {
          [className]: className,
        })}
      >
        {paginationRange &&
          paginationRange.length &&
          paginationRange.length > 0 &&
          paginationRange.map((pageNumber) => {
            if (pageNumber === DOTS) {
              return <li className="pagination-item dots">&#8230;</li>;
            }

            return (
              <li
                className={classnames("pagination-item", {
                  selected: pageNumber === currentPage,
                })}
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </li>
            );
          })}
      </ul>
      <div
        className={
          currentPage === lastPage
            ? "pagination-chevron pagination-disabled"
            : "pagination-chevron"
        }
        onClick={onNext}
      >
        <FaChevronRight color={currentPage === lastPage && "#ccc"} />
      </div>
    </div>
  );
};

export default Pagination;
