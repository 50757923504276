import React, { useEffect } from "react";
import "./style.css";
import HelpAndAdviceHeader from "./HelpAndAdviceHeader";
import HelpAndAdviceBestDeal from "./HelpAndAdviceBestDeal";
import HelpAndAdviceWheelchairAccessible from "./HelpAndAdviceWheelchairAccessible";
import HelpAndAdviceWheelchairKinds from "./HelpAndAdviceWheelchairKinds";
import HelpAndAdviceSize from "./HelpAndAdviceSize";
import HelpAndAdviceDecisions from "./HelpAndAdviceDecisions";
import HelpAndAdviceOthers from "./HelpAndAdviceOthers";
import HelpAndAdviceColorCards from "./HelpAndAdviceColorCards";
const HelpAndAdvice = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="help-and-advice-main">
      <HelpAndAdviceHeader />
      <HelpAndAdviceBestDeal />
      <HelpAndAdviceWheelchairAccessible />
      <HelpAndAdviceWheelchairKinds />
      <HelpAndAdviceSize />
      <HelpAndAdviceDecisions />
      <HelpAndAdviceOthers />
      <HelpAndAdviceColorCards />
    </div>
  );
};

export default HelpAndAdvice;
