import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const ProtectedProfile = ({ path, exact, children }) => {
  const auth = useSelector((state) => state.dealers.authenticated);
  return auth ? (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  ) :
  <></>
  //  (
  //   <Redirect to="/" />
  // );
};

export default ProtectedProfile;
