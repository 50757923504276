import React, { useState, useEffect } from "react";
import { BsChevronRight } from "react-icons/bs";
import AllMakes from "./AllMakes";
import ContactUs from "./ContactUs";
import DisabledMotoring from "./DisabledMotoring";
import "./navbar.css";
import PrivateDealers from "./PrivateSeller";
import TradeSeller from "./TradeSeller";
import Trending from "./Trending";
import WavType from "./WavType";
import WheelchairBus from "./WheelchairBus";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWavDetails, getWavs } from "../../redux/actions/wavActions";
const DropDownMenu = ({ name, setMenuOpen }) => {
  const [menuBar, setMenuBar] = useState(
    name === "buy" ? "wavType" : name === "sell" ? "private" : "wheelchair"
  );
  // const [type, setType] = useState("new");
  const [type, setType] = useState("old");

  const [activeMenu, setActiveMenu] = useState(name === "buy" ? "old" : name === "sell" ? "private" : "wheelchair")

  const dispatch = useDispatch();
  const detailsFromState = useSelector((state) => state.wavs.details);
  const wavsFromState = useSelector((state) => state.wavs.list);

  useEffect(() => {
    dispatch(getWavDetails());
    dispatch(getWavs());
  }, []);

  const history = useHistory();
  let styles = {
    backgroundColor: "rgb(206, 118, 36)",
    borderRadius: "5px"
  }

  return (
    <div className="dropdown-wrapper">
      <div className="row">
        <div className="tabs col-md-3">
          {name === "buy" && (
            <ul>
              {/* <li
                className="trendingMenuList"
                onClick={() => setMenuBar("trending")}
              >
                <div className="text">Trending</div>
                <div className="icon">
                  <BsChevronRight />
                </div>
              </li> */}
              <li
                onClick={() => {
                  setType("old");
                  setMenuBar("wavType");
                  setActiveMenu("old")
                }}
                className={activeMenu == 'old' ? "activemenu" : ""}
                style={activeMenu == 'old' ? styles : {}}
              >
                <div className="text">Used WAVs</div>
                <div className="icon">
                  <BsChevronRight />
                </div>
              </li>
              <li
                onClick={() => {
                  setType("new");
                  setMenuBar("wavType");
                  setActiveMenu("new")
                }}
                className={activeMenu == 'new' ? "activemenu" : ""}
                style={activeMenu == 'new' ? styles : {}}

              >
                <div className="text">New WAVs</div>
                <div className="icon">
                  <BsChevronRight />
                </div>
              </li>
              <li
                onClick={() => {
                  setType("mobility");
                  setMenuBar("wavType");
                  setActiveMenu("mobility")
                }}
                className={activeMenu == 'mobility' ? "activemenu" : ""}
                style={activeMenu == 'mobility' ? styles : {}}

              >
                <div className="text">Mobility</div>
                <div className="icon">
                  <BsChevronRight />
                </div>
              </li>
              <li onClick={() => {
                setMenuBar("allMakes")
                setActiveMenu("allMakes")
              }}
                className={activeMenu == 'allMakes' ? "activemenu last" : "last"}
                style={activeMenu == 'allMakes' ? styles : {}}
              >
                <div className="text ">All Makes</div>
                <div className="icon">
                  <BsChevronRight />
                </div>
              </li>
            </ul>
          )}
          {name === "sell" && (
            <ul>
              <li onClick={() => {
                setMenuBar("private")
                setActiveMenu("private")
              }}
                className={activeMenu == 'private' ? "activemenu" : ""}
                style={activeMenu == 'private' ? styles : {}}

              >
                <div className="text">Private Seller</div>
                <div className="icon">
                  <BsChevronRight />
                </div>
              </li>
              <li onClick={() => {
                setMenuBar("trade")
                setActiveMenu("trade")
              }}
                className={activeMenu == 'trade' ? "activemenu" : ""}
                style={activeMenu == 'trade' ? styles : {}}

              >
                <div className="text">Trade Seller</div>
                <div className="icon">
                  <BsChevronRight />
                </div>
              </li>
            </ul>
          )}
          {name === "more" && (
            <ul>
              <li onClick={() => {
                setMenuBar("wheelchair")
                setActiveMenu("wheelchair")
              }}
                className={activeMenu == 'wheelchair' ? "activemenu" : ""}
                style={activeMenu == 'wheelchair' ? styles : {}}

              >
                <div className="text">Wheelchair Bus</div>
                <div className="icon">
                  <BsChevronRight />
                </div>
              </li>
              <li onClick={() => {
                setMenuBar("disabled")
                setActiveMenu("disabled")
              }}
                className={activeMenu == 'disabled' ? "activemenu" : ""}
                style={activeMenu == 'disabled' ? styles : {}}

              >
                <div className="text">Disabled Motoring UK</div>
                <div className="icon">
                  <BsChevronRight />
                </div>
              </li>
              <li onClick={() => {
                setMenuBar("contact")
                setActiveMenu("contact")
              }}
                className={activeMenu == 'contact' ? "activemenu" : ""}
                style={activeMenu == 'contact' ? styles : {}}

              >
                <div className="text">Contact Us</div>
                <div className="icon">
                  <BsChevronRight />
                </div>
              </li>
            </ul>
          )}
        </div>
        <div className="tabs-info col-md-9 " style={{ height: "100%" }}>
          {name === "buy" && (
            <div>
              {" "}
              <div className="trending-menu">
                {menuBar === "trending" && (
                  <Trending details={detailsFromState} wavs={wavsFromState} />
                )}
              </div>
              <div className="wav-type-menu">
                {menuBar === "wavType" && (
                  <WavType wavs={wavsFromState} type={type} />
                )}
              </div>
              <div className="all-makes-menu">
                {menuBar === "allMakes" && <AllMakes />}
              </div>
            </div>
          )}
          {name === "sell" && (
            <div>
              <div className="private-seller">
                {menuBar === "private" && (
                  <PrivateDealers history={history} setMenuOpen={setMenuOpen} />
                )}
              </div>
              <div className="trade-seller">
                {menuBar === "trade" && (
                  <TradeSeller history={history} setMenuOpen={setMenuOpen} />
                )}
              </div>
            </div>
          )}
          {name === "more" && (
            <div>
              <div className="wheelchair-bus">
                {menuBar === "wheelchair" && (
                  <WheelchairBus history={history} setMenuOpen={setMenuOpen} />
                )}
              </div>
              <div className="disabled-motoring">
                {menuBar === "disabled" && (
                  <DisabledMotoring
                    history={history}
                    setMenuOpen={setMenuOpen}
                  />
                )}
              </div>
              <div className="contact-us">
                {menuBar === "contact" && (
                  <ContactUs history={history} setMenuOpen={setMenuOpen} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DropDownMenu;
