import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { wavReducer } from "./reducers/wavReducer";
import { dealerReducer } from "./reducers/dealerReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // You can choose the storage engine you want to use

// Configure the persist options
const persistConfig = {
  key: 'root',
  storage,
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const initialStore = {
  wavs: {
    list: [],
    selected: {},
    searched: [],
    searchedWords: [],
    details: [],
    makes: [],
    models: [],
  },
  dealers: {
    list: [],
    selected: {},
    dealer: {},
    authenticated: false,
    admin: false,
    modalOpen: false,
    selectedComponent: "",
    components: []
  },
  buyers: {
    list: [],
  },
};

const bigReducer = combineReducers({
  wavs: wavReducer,
  dealers: dealerReducer,
});

const persistedReducer = persistReducer(persistConfig, bigReducer);

const configureStore = createStore(
  persistedReducer,
  initialStore,
  composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(configureStore);

export { configureStore, persistor };
