import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createDealer, toggleModal } from '../../redux/actions/dealerActions';
import './style.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Createaccount = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [show, setShow] = useState(false)

    const [signupError, setSignupError] = useState("")

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required("First Name is required"),
        last_name: Yup.string().required("Last Name is required"),
        phone_number: Yup.string().required("Phone Number is required"),
        email: Yup.string().required("Email is required").email("Email is invalid"),
        password: Yup.string()
            .min(8, { length: "Password too short" })
            .matches(/\d+/, { message: { number: "Password no number" } })
            .matches(/[a-z]+/, { message: { lowercase: "Password no lowercase" } })
            .matches(/[A-Z]+/, { message: { uppercase: "Password no uppercase" } })
            .matches(/[!@#$%^&*()-+]+/, {
                message: { special: "Password no special char" }
            })
            .test(
                "Password has spaces",
                { spaces: "Password has spaces" },
                value => !/\s+/.test(value)
            )
            .required({ required: "password is required" })

    });


    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            first_name: "",
            last_name: "",
            phone_number: "",
        },
        validationSchema,
        // validateOnChange: false,
        // validateOnBlur: false,
        onSubmit: (data) => {
            
            handleSignUp(data)

        },
    });


    const handleSignUp = async (data) => {
        try {
            const name = data.first_name + " " + data.last_name;
            if (
                name !== "" &&
                data.password !== "" &&
                data.email !== "" &&
                data.phone_number !== ""
            ) {
                const signUpWith = await dispatch(
                    createDealer({
                        name,
                        login: data.email,
                        password: data.password,
                        email: data.email,
                        phone_number: data.phone_number,
                        role: "user",
                    })
                );

                if (signUpWith.status === "success") {
                    toast.success("Signup successfully")
                    history.push(`/profile/${signUpWith.id}`);
                    dispatch(toggleModal(false))
                    // document.body.classList.remove('overflow_hidden');
                } else {
                    setSignupError(signUpWith.error);
                    // toast.error(signUpWith.error)
                    setTimeout(() => {
                        setSignupError("");
                    }, 5000)
                }
            } else {
                setSignupError("All inputs are required")
                setTimeout(() => {
                    setSignupError("");
                }, 5000)
            }
        } catch (err) {
            console.log(err);
        }
    };

    console.log("formik.errors", formik.errors)
    return (
        <>
            <h1>Create Account</h1>
            {
                signupError &&
                (
                    <div className='error_msg1 mt-2'>
                        <p>{signupError}</p>
                    </div>
                )
            }

            <form className='mt-4 pl-3' onSubmit={formik.handleSubmit}>
                <div class="flex-fg">
                <div className='form-group'>
                    <label>First Name</label>
                    <input
                        name="first_name"
                        type="text"
                        // value={inputs.first_name}
                        placeholder="Enter First Name"
                        // required
                        // onChange={handleInputChange}
                        className={
                            'form-control' +
                            (formik.errors.first_name && formik.touched.first_name
                                ? ' is-invalid'
                                : '')
                        }
                        onChange={formik.handleChange}
                        value={formik.values.first_name}
                    />
                    <div className="invalid-feedback">
                        {formik.errors.first_name && formik.touched.first_name
                            ? formik.errors.first_name
                            : null}
                    </div>
                </div>
                <div className='form-group'>
                    <label>Last Name</label>
                    <input
                        name="last_name"
                        type="text"
                        // value={inputs.last_name}
                        placeholder="Enter Last Name"
                        // required
                        // onChange={handleInputChange}
                        className={
                            'form-control' +
                            (formik.errors.last_name && formik.touched.last_name
                                ? ' is-invalid'
                                : '')
                        }
                        onChange={formik.handleChange}
                        value={formik.values.last_name}
                    />
                    <div className="invalid-feedback">
                        {formik.errors.last_name && formik.touched.last_name
                            ? formik.errors.last_name
                            : null}
                    </div>
                </div></div>
                <div className='form-group'>
                    <label>Email Address</label>
                    <input
                        name="email"
                        type="email"
                        // value={inputs.email}
                        placeholder="Enter Email"
                        // required
                        // onChange={handleInputChange}

                        className={
                            'form-control' +
                            (formik.errors.email && formik.touched.email
                                ? ' is-invalid'
                                : '')
                        }
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                    <div className="invalid-feedback">
                        {formik.errors.email && formik.touched.email
                            ? formik.errors.email
                            : null}
                    </div>
                </div>
                <div className='form-group'>
                    <label>Phone Number</label>
                    <input
                        name="phone_number"
                        type="text"
                        // value={inputs.phone_number}
                        placeholder="Enter Phone Number"
                        // required
                        // onChange={handleInputChange}

                        className={
                            'form-control' +
                            (formik.errors.phone_number && formik.touched.phone_number
                                ? ' is-invalid'
                                : '')
                        }
                        onChange={formik.handleChange}
                        value={formik.values.phone_number}

                    />
                    <div className="invalid-feedback">
                        {formik.errors.phone_number && formik.touched.phone_number
                            ? formik.errors.phone_number
                            : null}
                    </div>
                </div>
                <div className='form-group'>
                    <label>Password</label>
                    <div className='position-relative mb-4'>
                        <input
                            type={show ? "text" : "password"}
                            name="password"
                            placeholder="Enter Password"
                            className={
                                'form-control' +
                                (formik.errors.password && formik.touched.password
                                    ? ' is-invalid'
                                    : '')
                            }
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                        <button type="button" className='btn show_btn p-0 bg-transparent' style={{top:"35%", right:"35px"}} onClick={() => setShow(!show)}>{show ? "HIDE" : "SHOW"}</button>
                        <div className="invalid-feedback invalid-feedback-postn">
                            {formik.errors.password && formik.touched.password
                                ? (formik.errors.password.lowercase || formik.errors.password.uppercase || formik.errors.password.length || formik.errors.password.required || formik.errors.password.spaces || formik.errors.password.special || formik.errors.password.number)
                                : null}
                        </div>
                    </div>

                </div>
                <div className="form-group form-check pt-3">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label" htmlFor="exampleCheck1">I'm happy to receive emails and about deals and offers from Wavcompare!</label>
                </div>
                <button type="submit" className='btn main_btn1 text-white mt-4'>Sign Up</button>
            </form>
        </>
    )
}
export default Createaccount;