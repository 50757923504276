import React, { useState, useEffect } from "react";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { CgTwitter } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./style.css";
import { createDealer, loginDealer, socialLogin } from "../../redux/actions/dealerActions";
import { toast } from "react-toastify";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import './google-button.css'
import FacebookLogin from "react-facebook-login";

const SignUpSignIn = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [emailForLogin, setEmailForLogin] = useState("");
  const [passwordForLogin, setPasswordForLogin] = useState("");
  const [loginErrorAlert, setLoginErrorAlert] = useState(false);
  const [loginSuccessAlert, setLoginSuccessAlert] = useState(false);
  const [signupErrorAlert, setSignupErrorAlert] = useState(false);
  const [signupSuccessAlert, setSignupSuccessAlert] = useState(false);
  const [signupError, setSignupError] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const [googleUser, setGoogleUser] = useState();
  // const [googleProfile, setGoogleProfile] = useState();


  const handleSocialLogin = async (googleUser = null, fbUser = null) => {
    // e.preventDefault();
    try {
      let socialLoginBody = {}
      if (googleUser) {
        socialLoginBody = {
          name: googleUser.name,
          login: googleUser.email,
          email: googleUser.email,
          picture: googleUser.picture,
          role: "user",
          google_id: googleUser.id,
          loginMethod: 2
        }
      }
      else if (fbUser) {
        socialLoginBody = {
          name: fbUser.name,
          ...(fbUser.email ? { login: fbUser.email } : {}),
          ...(fbUser.email ? { email: fbUser.email } : {}),
          picture: fbUser.picture.data.url,
          role: "user",
          fb_id: fbUser.id,
          loginMethod: 3
        }
      }

      const socialLoginResp = await dispatch(
        socialLogin(socialLoginBody)
      );

      if (socialLoginResp.status === "success") {
        toast.success("Login successfully")
        history.push(`/profile/${socialLoginResp.id}`);
      } else {
        setSignupError(socialLoginResp.error);
        toast.error(socialLoginResp.error)
      }

    } catch (err) {
      console.log(err);
    }
  };


  useEffect(
    () => {
      if (googleUser) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`, {
            headers: {
              Authorization: `Bearer ${googleUser.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            // console.log("res.data", res.data)
            // setGoogleProfile(res.data);
            handleSocialLogin(res.data, null)
          })
          .catch((err) => console.log(err));
      }
    },
    [googleUser]
  );

  // log out function to log the user out of google and set the profile array to null
  // const googlelogOutFunc = () => {
  //   googleLogout();
  //   // setGoogleProfile(null);
  // };

  const googleLoginFunc = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setGoogleUser(codeResponse)
    },
    onError: (error) => console.log('Login Failed:', error)
  });


  const handleLogin = async (e) => {
    e.preventDefault();
    if (emailForLogin != '' && passwordForLogin != '') {
      const loggedWith = await dispatch(
        loginDealer({
          login: emailForLogin,
          password: passwordForLogin,
        })
      );
      if (loggedWith.status === "success") {
        toast.success("Login successfully")
        setLoginSuccessAlert(true);
        // setTimeout(() => {
        setLoginSuccessAlert(false);
        loggedWith.id && history.push(`/profile/${loggedWith.id}`);
        // }, 2000);
      } else {
        toast.error("Password or Email is incorrect. Please try again.")
        setLoginErrorAlert(true);
        // setTimeout(() => {
        setLoginErrorAlert(false);
        // }, 2000);
      }
    }

  };
  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const name = firstName + " " + lastName;

      if (
        name !== "" &&
        // login !== "" &&
        password !== "" &&
        email !== "" &&
        phone !== ""
      ) {
        const signUpWith = await dispatch(
          createDealer({
            name,
            login: email,
            password,
            email,
            phone_number: phone,
            role: "user",
          })
        );

        if (signUpWith.status === "success") {
          // setSignupSuccessAlert(true);
          // setTimeout(() => {
          // setSignupSuccessAlert(false);
          toast.success("Signup successfully")
          history.push(`/profile/${signUpWith.id}`);
          // }, 2000);
        } else {
          // setSignupErrorAlert(true);
          // console.log("check me:", signUpWith.error);
          setSignupError(signUpWith.error);
          // setTimeout(() => {
          toast.error(signUpWith.error)

          // setSignupErrorAlert(false);
          // }, 2000);
        }
      } else {
        setSignupErrorAlert(true);
        setSignupError("Please fill in all the details");
        setTimeout(() => {
          setSignupErrorAlert(false);
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const [fbLogin, setFbLogin] = useState(false);
  // const [fbData, setFbData] = useState({});
  // const [fbPicture, setFbPicture] = useState("");

  const responseFacebook = (response) => {
    console.log(response);
    // Login failed
    if (response.status === "unknown") {
      // alert("Login failed!");
      toast.error("Login failed!")
      setFbLogin(false);
      return false;
    }
    // setFbData(response);
    // setFbPicture(response.picture.data.url);
    if (response.accessToken) {
      handleSocialLogin(null, response)
      setFbLogin(true);
    } else {
      setFbLogin(false);
    }
  };
  // const fbLogout = () => {
  //   setFbLogin(false);
  //   setFbData({});
  //   setFbPicture("");
  // };

  return (
    <div id="signUpSignIn">
      <div className="header-wrapper">
        <div className="header">LOGIN/REGISTER</div>
      </div>
      <div className="main-content">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-4 sign-in">
            <h1>Sign In</h1>
            <h6 style={{ color: "#f97171" }}>Please use your email to login</h6>
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label htmlFor="email-login">E-mail</label>
                <input
                  value={emailForLogin}
                  onChange={(e) => setEmailForLogin(e.target.value)}
                  type="email"
                  className="form-control"
                  id="email-login"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password-login">Password</label>
                <input
                  value={passwordForLogin}
                  onChange={(e) => setPasswordForLogin(e.target.value)}
                  type="password"
                  className="form-control"
                  id="password-login"
                  placeholder="Password"
                />
              </div>
              <div className="form-check">
                {/* <input
                  type="checkbox"
                  className="form-check-input"
                  id="rememberMe"
                /> */}
                <label className="form-check-label" htmlFor="rememberMe">
                  <div></div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/contact-us")}
                  >
                    Forgot Password
                  </div>
                </label>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
              {loginErrorAlert && (
                <div className=" mt-5 alert alert-danger" role="alert">
                  Password or Email is incorrect. Please try again.
                </div>
              )}
              {loginSuccessAlert && (
                <div className=" mt-5 alert alert-success" role="alert">
                  Success!
                </div>
              )}
            </form>

            {/* <div className="connect-with-social-media">
              <h3>Connect with:</h3>
              <div className="icons">
                <div className="icon">
                  <FaFacebookSquare />
                </div>
                <div className="icon">
                  <FcGoogle />
                </div>
                <div className="icon">
                  <CgTwitter />
                </div>
                <div className="icon">
                  <FaInstagram />
                </div>
              </div>
            </div> */}
          </div>

          <div className="col-sm-12 col-md-12 col-lg-7 sign-up">
            <h1>Sign Up</h1>
            <form onSubmit={(e) => handleSignUp(e)} className="row">
              <div className="col-sm-12 col-md-6 first-column">
                <div className="form-group">
                  <label htmlFor="first-name">First Name</label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="form-control"
                    id="first-name"
                    aria-describedby="first-name"
                    placeholder="Enter your first name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    className="form-control"
                    id="phone"
                    aria-describedby="phone"
                    placeholder="Enter your phone number"
                    required
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="email">Login</label>
                  <input
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                    type="text"
                    className="form-control"
                    id="login"
                    aria-describedby="login"
                    placeholder="Enter your login name"
                    required
                  />
                </div> */}

                <div className="form-group">
                  <label htmlFor="password-sign-up">Password</label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control"
                    id="password-sign-up"
                    min={8}
                    aria-describedby="password"
                    placeholder="Enter your password"
                    required
                  />
                  <small
                    id="password-sign-up-alert"
                    className="form-text text-danger"
                  >
                    {password.length < 8 &&
                      "Please enter at least 8 characters"}
                  </small>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 first-column">
                <div className="form-group">
                  <label htmlFor="last-name">Last Name</label>
                  <input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    className="form-control"
                    id="last-name"
                    aria-describedby="lastName"
                    placeholder="Enter your last name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email-sign-up">E-mail</label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    id="email-sign-up"
                    aria-describedby="emailHelp"
                    placeholder="Enter your email"
                    required
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="password-sign-up">Password</label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control"
                    id="password-sign-up"
                    min={8}
                    aria-describedby="password"
                    placeholder="Enter your password"
                    required
                  />
                  <small
                    id="password-sign-up-alert"
                    className="form-text text-danger"
                  >
                    {password.length < 8 &&
                      "Please enter at least 8 characters"}
                  </small>
                </div> */}
              </div>
              <div className="lower">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="accept"
                    required
                  />
                  <label className="form-check-label" htmlFor="accept">
                    I accept the terms of the
                  </label>
                </div>
                <button type="submit" className="btn btn-primary">
                  Sign Up Now!
                </button>
                {signupErrorAlert && (
                  <div className=" mt-5 alert alert-danger" role="alert">
                    {signupError}
                  </div>
                )}
                {signupSuccessAlert && (
                  <div className=" mt-5 alert alert-success" role="alert">
                    Success!
                  </div>
                )}
              </div>
            </form>

            <div className="social_btn">
              <div class="google-btn" onClick={() => googleLoginFunc()}>
                <div class="google-icon-wrapper">
                  <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
                </div>
                <p class="btn-text"><b>Sign in with google</b></p>
              </div>

              {/* <div className="container"> */}
              {/* {!fbLogin && ( */}
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                autoLoad={false}
                fields="name,email,picture"
                scope="public_profile,email,user_friends"
                callback={responseFacebook}
                icon="fa-facebook"
              />
              {/* )} */}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpSignIn;
