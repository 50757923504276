import React, { useEffect } from "react";
import MiniBusHeader from "./MiniBusHeader";
import MiniBusMainContent from "./MiniBusMainContent";
import "./style.css";
const MiniBus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="minibus">
      <MiniBusHeader />
      <MiniBusMainContent />
    </div>
  );
};

export default MiniBus;
