import React from "react";
import { AiFillStar } from "react-icons/ai";
import DisplayRating from "./DisplayRating";

const DealerPageReviewCard = ({ review }) => {
  const overallReview = Number(
    (review.buying_process +
      review.customer_service +
      review.overall_experience) /
      3
  ).toFixed(1);
  const averageCustomerService = Number(review.customer_service).toFixed(1);
  const averageBuyingProcess = Number(review.buying_process).toFixed(1);
  const averageOverallExperience = Number(review.overall_experience);
  return (
    <div className="dealer-review-card my-5">
      <div className="detailed-rating">
        <div className="overall">
          <h6>{overallReview}</h6>
          <div className="stars">
            <DisplayRating name="overall-rating" value={overallReview} />
          </div>
        </div>
        <div className="c-service">
          <h6>Customer Service</h6>
          <div className="reviews-star">
            <div className="stars">
              <DisplayRating
                name="customer-service"
                value={averageCustomerService}
              />
            </div>
            <div className="review-text">
              {averageCustomerService} out of 5.0
            </div>
          </div>
        </div>
        <div className="buying-process">
          <h6>Buying Process</h6>
          <div className="reviews-star">
            <div className="stars">
              <DisplayRating
                name="buying-process"
                value={averageBuyingProcess}
              />
            </div>
            <div className="review-text">{averageBuyingProcess} out of 5.0</div>
          </div>
        </div>
        <div className="overall-experience">
          <h6>Overall Experience</h6>
          <div className="reviews-star">
            <div className="stars">
              <DisplayRating
                name="overall-experience"
                value={averageOverallExperience}
              />
            </div>
            <div className="review-text">
              {averageOverallExperience} out of 5.0
            </div>
          </div>
        </div>
      </div>
      <div className="dealer-review-content">
        <div className="dealer-review-card-title">{review.title}</div>
        <div className="dealer-review-card-description">
          {review.review}
          <strong className="author">- {review.name}</strong>
        </div>
        <div className="dealer-review-card-footer text-muted">
          By <a href="#">Google Reviews</a> | Would you recommend this Dealer:
          <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>
            {" "}
            {review.recommendation}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DealerPageReviewCard;
