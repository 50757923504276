import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import Cards from "./Cards";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { useHistory } from "react-router-dom";
const Showcase = () => {
  const [selected, setSelected] = useState("passengers");
  const [wavs, setWavs] = useState([]);
  const [showSelected, setShowSelected] = useState("new");
  const wavsFromState = useSelector((state) => state.wavs.list);
  const history = useHistory();
  useEffect(() => {
    setWavs(wavsFromState);
  }, [wavsFromState]);
  useEffect(() => {
    if (showSelected === "new") {
      setWavs(
        wavsFromState.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )
      );
    } else if (showSelected === "old") {
      setWavs(
        wavsFromState.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        )
      );
    } else {
      setWavs(wavsFromState.filter((wav) => wav.featured === true));
    }
  }, [showSelected]);
  const adaptationFilter =
    selected === "passengers"
      ? wavs &&
        wavs.length > 0 &&
        wavs.filter((wav) => wav.wav_type !== "Drive From Wheelchair")
      : wavs &&
        wavs.length > 0 &&
        wavs.filter((wav) => wav.wav_type === "Drive From Wheelchair");
  const filtered =
    adaptationFilter &&
    adaptationFilter.length > 0 &&
    adaptationFilter.filter((wav) => wav.status === "active");
  return (
    <div className="wrapper showcase-wrapper">
      <h1>
        Find and compare over 500 WAVs for sale - from local specialist WAV
        dealers and the private sellers accross the UK
      </h1>
      <div className="upper-part">
        <div className="types d-flex">
          <div
            onClick={() => setSelected("passengers")}
            className={
              selected === "passengers" ? "passengers on-focus" : "passengers"
            }
          >
            ADAPTED FOR PASSENGERS
          </div>
          <div
            onClick={() => setSelected("drivers")}
            className={selected === "drivers" ? "drivers on-focus" : "drivers"}
          >
            ADAPTED FOR DRIVERS
          </div>
        </div>
      </div>
      <div className="showcase-navbar">
        <ul>
          <li
            className={showSelected === "new" ? "showcase-selected" : null}
            onClick={() => setShowSelected("new")}
          >
            New WAVs
          </li>
          <li
            className={showSelected === "featured" ? "showcase-selected" : null}
            onClick={() => setShowSelected("featured")}
          >
            Featured Items
          </li>
          <li
            className={showSelected === "old" ? "showcase-selected" : null}
            onClick={() => setShowSelected("old")}
          >
            Old WAVs
          </li>
          {/* <li
            className={showSelected === "featured" ? "showcase-selected" : null}
            onClick={() => setShowSelected("featured")}
          >
            Featured Items
          </li> */}
        </ul>
      </div>
      <div className="cards">
        {filtered && filtered.length > 0 && <Cards wavs={filtered} />}
        <button
          onClick={() => {
            history.push(`/buy`);
          }}
          className="btn btn-lg show-all"
        >
          Show All
        </button>
      </div>
    </div>
  );
};

export default Showcase;
