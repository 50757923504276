import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { GiGearStickPattern } from "react-icons/gi";
import { FaRoad, FaWheelchair } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getWav, getWavBySlug } from "../../../redux/actions/wavActions";
import { useHistory } from "react-router-dom";
import "./button.scss";
import { formatWavUrlString } from "../../../Helpers/utils";

export default function SingleCard({ wav }) {
  const [expanded, setExpanded] = React.useState(false);
  const selectedWav = useSelector((state) => state.wavs.selected);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  // const price = parseFloat(wav.price);

  let price = 0
  if (Object.entries(wav).length > 0) {
    wav.price_2 = wav.price.replace(/[^0-9]/g, "")
    // console.log(wav)
    price = parseFloat(wav.price_2);
    // console.log(price)

    wav.mileage = wav.mileage.replaceAll(`\"`, "")
  }


  const seating = wav.seating.replace(/\band\b/g, "+");
  const title = wav.title.substring(0, 40);

  const getWavdetails = async (wavId) => {
    const resp = await dispatch(getWav(wavId));
    if (resp?.success) {
      var slug = resp?.data?.wav?.slug ? resp?.data?.wav?.slug : resp?.data?.wav?.title.replaceAll(' ', '_');
      history.push(`/wavs/${slug}`, {
        wav_id: wavId
      });
    }

  }
  return (
    <Card
      onClick={() => {
        getWavdetails(wav?._id)
        //dispatch(getWavBySlug(wav.slug, history));
        // history.push(`/wavs/${wav._id}`, {
        //   wav_id: wav._id
        // });

        // history.push(`/wavs/${formatWavUrlString(wav)}`, {
        //   wav_id: wav._id
        // });    



      }}
      style={{ cursor: "pointer", borderRadius: "20px", padding: "10px 20px" }}
    >
      <CardHeader className="option-header" title={`${title}`} />
      <CardMedia
        component="img"
        className="wav-image"
        image={
          `https://dev.wavcompare.com/uploads/${(wav.featured_image && wav.featured_image.replace("images/", "images/thumbnails/")) || (wav.images && wav.images.length > 0 && wav.images[0].replace("images/", "images/thumbnails/"))}` ||
          "https://www.exploremobility.co.uk/assets/imgs/jpgs/slide1.jpg"
        }
        alt="car"
        loading="lazy"
      />
      <div className="buttons">
        <a href="#" className="price-btn">
          <span>£{price.toLocaleString()}</span>
        </a>
        {/* <a href={window.location.origin + `/wavs/${formatWavUrlString(wav)}`} onClick={(event) => event.preventDefault()} className="enquire-btn"> */}

        <a href={window.location.origin + `/wavs/${wav.slug}`} onClick={(event) => event.preventDefault()} className="enquire-btn">

          <span>ENQUIRE NOW</span>
        </a>
      </div>
      <CardContent>
        <Typography className="options" variant="body2" color="text.secondary">
          <div className="icon-options">
            <div style={{ textTransform: "capitalize" }}>
              <span>
                <FaWheelchair />
              </span>
              {wav.condition}
            </div>
            <div>
              <span>
                <FaRoad />
              </span>
              {wav.mileage}
            </div>
          </div>
          <div className="icon-options">
            <div>
              <span>
                <FaWheelchair />
              </span>
              {seating}
            </div>

            <div className="road">
              <span>
                {/* <GiGearStickPattern /> */}
                <img
                  style={{ width: "30px" }}
                  src="/assets/icon-1.png"
                  alt="icon"
                />
              </span>
              {wav.transmission}
            </div>
          </div>
        </Typography>
      </CardContent>
    </Card>
  );
}
