import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
export default function BasicSelect({
  name,
  bodyType,
  make,
  model,
  keywords,
  handleChange,
  list,
}) {
  const theme = createTheme({
    breakpoints: {
      values: {
        xxs: 0, // small phone
        xs: 300, // phone
        sm: 600, // tablets
        md: 900, // small laptop
        lg: 1200, // desktop
        xl: 1536, // large screens
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div>
        <FormControl
          sx={{
            fontSize: {
              xxs: "10px",
              xs: "12px",
              sm: "12px",
              md: "13px",
              lg: "14px",
              xl: "14px",
            },
            mx: 1,
            width: {
              xxs: "100px",
              xs: "130px",
              sm: "150px",
              md: "160px",
              lg: "190px",
              xl: "230px",
            },
            minWidth: 120,
            backgroundColor: "white",
          }}
          variant="standard"
        >
          <InputLabel
            sx={{
              fontSize: {
                xxs: "10px",
                xs: "12px",
                sm: "13px",
                md: "15px",
                lg: "16px",
                xl: "16px",
              },
              textTransform: "capitalize",
              background: "#fff",
              color: "#333",
              "&:focus": {
                backgroundColor: "red",
              },
            }}
            id="demo-simple-select-standard-label"
          >
            {name}
          </InputLabel>
          {name === "Choose Body" && (
            <Select
              sx={{
                fontSize: {
                  xxs: "10px",
                  xs: "12px",
                  sm: "13px",
                  md: "15px",
                  lg: "16px",
                  xl: "16px",
                },
                textTransform: "capitalize",
              }}
              labelId="body-type"
              id="body-type"
              value={bodyType}
              className="body-select"
              onChange={(e) => handleChange(e, "body")}
              label="Body"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {list &&
                list.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
            </Select>
          )}
          {name === "Choose Make" && (
            <Select
              labelId="make"
              id="make"
              value={make}
              onChange={(e) => handleChange(e, "make")}
              label="Make"
            >
              <MenuItem value="">
                {list.length > 0 && (
                  <em>None</em>
                )
                  // : (
                  //   <em>Please Choose the body first</em>
                  // )
                }
              </MenuItem>
              {list &&
                list.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
            </Select>
          )}
          {name === "Choose Model" && (
            <Select
              labelId="model"
              id="model"
              value={model}
              onChange={(e) => handleChange(e, "model")}
              label="Model"
            >
              <MenuItem value="">
                {list.length > 0 && (
                  <em>None</em>
                )
                  // : (
                  //   <em>Please choose the body and make first</em>
                  // )
                }
              </MenuItem>
              {list &&
                list.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
            </Select>
          )}
          {name === "Keywords" && (
            <Select
              labelId="keywords"
              id="keywords"
              value=""
              onChange={(e) => handleChange(e, "size")}
              label="Keywords"
            >
              <MenuItem value="">
                {list.length > 0 ? (
                  <em>None</em>
                ) : (
                  <em>Please choose the make or model first</em>
                )}
              </MenuItem>
              {list &&
                list.map((el) => (
                  <MenuItem
                    style={{ textTransform: "capitalize" }}
                    key={el}
                    value={el}
                  >
                    {el}
                  </MenuItem>
                ))}
            </Select>
          )}
        </FormControl>
      </div>
    </ThemeProvider>
  );
}
