import React, { useState } from "react";
import { AiFillCar, AiFillStar } from "react-icons/ai";
import { MdNoteAlt } from "react-icons/md";
import DealerPageNavPanelReviewList from "./DealerPageNavPanelReviewList";
import DealerPageNavPanelStock from "./DealerPageNavPanelStock";
import DealerWriteReview from "./DealerWriteReview";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
const DealerPageNavPanel = ({ dealer }) => {
  const [selected, setSelected] = useState("stock");
  const cookies = new Cookies();
  const user = cookies.get("user");
  const jwt = cookies.get("jwt");
  const auth = user && jwt ? true : false;
  const history = useHistory();
  return (
    <div className="dealer-page-nav-panel">
      <div className="dealer-panel-nav">
        <div
          onClick={() => setSelected("stock")}
          className={
            selected === "stock"
              ? "dealer-stock dealer-panel-selected "
              : "dealer-stock"
          }
        >
          <span>
            <AiFillCar />
          </span>
          DEALER'S STOCK
        </div>
        <div
          onClick={() => setSelected("reviews")}
          className={
            selected === "reviews"
              ? "dealer-review dealer-panel-selected"
              : "dealer-review"
          }
        >
          <span>
            <AiFillStar />
          </span>
          DEALER REVIEWS
        </div>
        <div
          onClick={() => setSelected("write-review")}
          className={
            selected === "write-review"
              ? "write-review dealer-panel-selected"
              : "write-review"
          }
        >
          <span>
            <MdNoteAlt />
          </span>
          WRITE A REVIEW
        </div>
      </div>
      <div className="dealer-panel-nav-main-content">
        {selected === "stock" && (
          <div className="dealer-panel-nav-stock">
            <DealerPageNavPanelStock dealer={dealer} />
          </div>
        )}
        {selected === "reviews" && (
          <div className="dealer-panel-nav-review-list">
            {dealer && <DealerPageNavPanelReviewList dealer={dealer} />}
          </div>
        )}
        {selected === "write-review" && (
          <div className="dealer-write-review">
            {auth ? (
              dealer && (
                <DealerWriteReview setSelected={setSelected} dealer={dealer} />
              )
            ) : (
              <button
                onClick={() => history.push("/signup")}
                className="btn btn-primary"
              >
                Please login to leave a review
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DealerPageNavPanel;
