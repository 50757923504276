import {
  CHECK_LOGGED_IN_USER,
  CREATE_DEALER,
  GET_DEALER,
  GET_DEALERS,
  LOGIN_DEALER,
  LOGOUT_DEALER,
  MODAL_STATE,
  SELECT_COMPONENT,
  BACK_COMPONENT,
  RESET_AUTH_STORE
} from "../constants/constants";
import { initialStore } from "../store";

export const dealerReducer = (state = initialStore.dealers, action) => {
  switch (action.type) {
    case GET_DEALERS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case GET_DEALER: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    case CREATE_DEALER: {
      return {
        ...state,
        dealer: action.payload,
        authenticated: true,
      };
    }
    case LOGIN_DEALER: {
      return {
        ...state,
        dealer: action.payload,
        authenticated: true,
      };
    }
    case CHECK_LOGGED_IN_USER: {
      return {
        ...state,
        authenticated: true,
        dealer: action.payload,
      };
    }
    case LOGOUT_DEALER: {
      return {
        ...state,
        authenticated: false,
      };
    }
    case MODAL_STATE: {
      return {
        ...state,
        modalOpen: action.payload
      }
    }

    case SELECT_COMPONENT: {
      return {
        ...state,
        selectedComponent: action.payload,
        components: [...state.components, action.payload]
      }

    }
    
    case BACK_COMPONENT: {
      console.log("state.components----",state.components)
      state.components.pop()
      let newComp = state.components[state.components.length - 1] || ''
      console.log("AFTER POP state.components----",state.components)

      return {
        ...state,
        selectedComponent: newComp,
        components: [...state.components]
      }
    }


    case RESET_AUTH_STORE: {
      return {
        ...initialStore.dealers
      }
    }

    default:
      return state;
  }
};
