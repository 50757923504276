import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import StripeCheckout from "react-stripe-checkout";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

const MySwal = withReactContent(Swal);

// console.log("process.env.REACT_APP_STRIPE_API", process.env.REACT_APP_STRIPE_API)

const SellYourWavPricing = ({ singlePackage, url }) => {
  console.log("singlePackage", singlePackage)
  const publishableKey = process.env.REACT_APP_STRIPE_API;
  // parseInt((payment * 100).toFixed(2))
  const priceForStripeIndividual = parseInt((parseFloat(singlePackage.individual) * 100).toFixed(2));
  const priceForStripePackage1 = parseInt((parseFloat(singlePackage.packeg_1) * 100).toFixed(2));
  const priceForStripePackage2 = parseInt((parseFloat(singlePackage.packeg_2) * 100).toFixed(2));
  const priceForStripePackage3 = parseInt((parseFloat(singlePackage.packeg_3) * 100).toFixed(2));
  const cookies = new Cookies();
  const user = cookies.get("user");
  const jwt = cookies.get("jwt");
  const auth = user && jwt;
  const history = useHistory();

  const handleSuccess = () => {
    MySwal.fire({
      icon: "success",
      title: "Payment was successful",
      time: 4000,
    });
  };
  const handleFailure = () => {
    MySwal.fire({
      icon: "error",
      title: "Payment was not successful",
      time: 4000,
    });
  };

  const payNow = async (token, payment, type) => {
    try {
      console.log("payment", payment)
      const details = {
        token,
        amount: parseInt((payment * 100).toFixed(2)),
        type,
      };

      console.log("payment details:", details);
      const response = await fetch(`${url}/payment/pay`, {
        method: "POST",
        body: JSON.stringify(details),
        headers: {
          authorization: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        toast.success("Payment successfull")
        console.log("check payment success: ", data);
      } else {
        const data = await response.json();
        toast.error("Payment failed")

        console.log("check payment error: ", data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log("priceForStripeIndividual", priceForStripeIndividual)
  return (
    <div className="sell-your-wav-pricing">
      <div className="wrapper">
        {/* <h1>OUR INDUSTRY LEADING PACKAGES</h1> */}
        <h2>OUR INDUSTRY LEADING PACKAGES</h2>
        <div className="pricing-cards">
          <div className="side-pricing">
            <h3>Individual</h3>
            <h6>Complete advert listing and upload photos</h6>
            <div className="day-and-price">
              {/* <span>£19.99</span> one off payment */}
              <span>£{singlePackage.individual}</span> one off payment
            </div>
            {/* <div className="listings">
              <div className="listing">
                <div className="icon">
                  <AiOutlinePlus />
                </div>
                <div className="text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Reprehenderit eaque debitis, voluptates sit totam qui
                  inventore quas facilis deleniti laborum?
                </div>
              </div>
              <div className="listing">
                <div className="icon">
                  <AiOutlinePlus />
                </div>
                <div className="text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Reprehenderit eaque debitis, voluptates sit totam qui
                  inventore quas facilis deleniti laborum?
                </div>
              </div>
              <div className="listing">
                <div className="icon">
                  <AiOutlinePlus />
                </div>
                <div className="text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Reprehenderit eaque debitis, voluptates sit totam qui
                  inventore quas facilis deleniti laborum?
                </div>
              </div>
            </div> */}
            <div>
              {auth ? (
                <StripeCheckout
                  stripeKey={publishableKey}
                  currency="GBP"
                  label="Pay Now"
                  name="Pay With Credit Card"
                  billingAddress
                  shippingAddress
                  amount={priceForStripeIndividual}
                  description={`Your total is £${singlePackage.individual}`}
                  token={(token) =>
                    payNow(
                      token,
                      parseFloat(singlePackage.individual),
                      "individual"
                    )
                  }
                >
                  <button>Pay Now</button>
                </StripeCheckout>
              ) : (
                <button className="btn-margin" onClick={() => history.push("/signup")}>
                  Login to buy this package
                </button>
              )}
            </div>
          </div>
          <div className="side-pricing">
            <h3>Package 1</h3>
            <h6>Complete advert listing and upload photos</h6>
            <div className="lets-talk">BRONZE</div>
            <div className="listings">
              <div className="listing">
                <div className="icon">
                  <AiOutlinePlus />
                </div>
                <div className="text">
                  {/* Sell up to 5 vehicles per month @ £75 per month */}
                  Sell up to 5 vehicles per month @ £{singlePackage.packeg_1} per month
                </div>
              </div>
              <div className="listing">
                <div className="icon">
                  <AiOutlinePlus />
                </div>
                <div className="text">
                  ONLY £15.00 per vehicle ad!
                </div>
              </div>
              <div className="listing">
                <div className="icon">
                  <AiOutlinePlus />
                </div>
                <div className="text">
                  Attract your target customers through our quick and easy ads on our dedicated platforms
                </div>
              </div>
              <div>
                {auth ? (
                  <StripeCheckout
                    stripeKey={publishableKey}
                    currency="GBP"
                    label="Pay Now"
                    name="Pay With Credit Card"
                    billingAddress
                    shippingAddress
                    amount={priceForStripePackage1}
                    description={`Your total is £${singlePackage.packeg_1}`}
                    token={(token) =>
                      payNow(
                        token,
                        parseFloat(singlePackage.packeg_1),
                        "packeg_1"
                      )
                    }
                  >
                    <button>Pay Now</button>
                  </StripeCheckout>
                ) : (
                  <button onClick={() => history.push("/signup")}>
                    Login to buy this package
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="side-pricing">
            <h3>Package 2</h3>
            <h6>Complete advert listing and upload photos</h6>
            <div className="lets-talk">SILVER</div>
            <div className="listings">
              <div className="listing">
                <div className="icon">
                  <AiOutlinePlus />
                </div>
                <div className="text">
                  {/* Sell up to 20 vehicles per month @ £240 per month */}
                  Sell up to 20 vehicles per month @ £{singlePackage.packeg_2} per month
                </div>
              </div>
              <div className="listing">
                <div className="icon">
                  <AiOutlinePlus />
                </div>
                <div className="text">
                  ONLY £12.00 per vehicle ad!
                </div>
              </div>
              <div className="listing">
                <div className="icon">
                  <AiOutlinePlus />
                </div>
                <div className="text">
                  Attract your target customers through our quick and easy ads on our dedicated platforms
                </div>
              </div>
            </div>

            <div>
              {auth ? (
                <StripeCheckout
                  stripeKey={publishableKey}
                  currency="GBP"
                  label="Pay Now"
                  name="Pay With Credit Card"
                  billingAddress
                  shippingAddress
                  amount={priceForStripePackage2}
                  description={`Your total is £${singlePackage.packeg_2}`}
                  token={(token) =>
                    payNow(
                      token,
                      parseFloat(singlePackage.packeg_2),
                      "packeg_2"
                    )
                  }
                >
                  <button>Pay Now</button>
                </StripeCheckout>
              ) : (
                <button onClick={() => history.push("/signup")}>
                  Login to buy this package
                </button>
              )}
            </div>
          </div>
          <div className="side-pricing">
            <h3>Package 3</h3>
            <h6>Complete advert listing and upload photos</h6>
            <div className="lets-talk">GOLD</div>
            <div className="listings">
              <div className="listing">
                <div className="icon">
                  <AiOutlinePlus />
                </div>
                <div className="text">
                  {/* Sell up to 50 vehicles per month @ £400 per month */}
                  Sell up to 50 vehicles per month @ £{singlePackage.packeg_3} per month

                </div>
              </div>
              <div className="listing">
                <div className="icon">
                  <AiOutlinePlus />
                </div>
                <div className="text">
                  ONLY £8 per vehicle ad!
                </div>
              </div>
              <div className="listing">
                <div className="icon">
                  <AiOutlinePlus />
                </div>
                <div className="text">
                  Attract your target customers through our quick and easy ads on our dedicated platforms
                </div>
              </div>
            </div>
            <div>
              {auth ? (
                <StripeCheckout
                  stripeKey={publishableKey}
                  currency="GBP"
                  label="Pay Now"
                  name="Pay With Credit Card"
                  billingAddress
                  shippingAddress
                  amount={priceForStripePackage3}
                  description={`Your total is £${singlePackage.packeg_3}`}
                  token={(token) =>
                    payNow(
                      token,
                      parseFloat(singlePackage.packeg_3),
                      "packeg_3"
                    )
                  }
                >
                  <button>Pay Now</button>
                </StripeCheckout>
              ) : (
                <button onClick={() => history.push("/signup")}>
                  Login to buy this package
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellYourWavPricing;
