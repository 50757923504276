import React, { useEffect, useState } from "react";
import { MdGridView } from "react-icons/md";
import SearchSortBy from "../Search/SearchSortBy";
import { FaListUl } from "react-icons/fa";
import DealerPageNavPanelCards from "./DealerPageNavPanelCards";
import { useDispatch, useSelector } from "react-redux";

const DealerPageNavPanelStock = ({ dealer }) => {
  const [sortedWavs, setSortedWavs] = useState([]);
  const dispatch = useDispatch();
  const wavs = useSelector((state) => state.wavs.list);
  useEffect(() => {
    const sorted = wavs.filter((wav) => wav.owner._id === dealer._id);
    setSortedWavs(sorted);
  }, [wavs]);
  return (
    <div className="dealer-page-nav-panel-stock">
      <div className="dealer-page-nav-panel-stock-header">
        <h4 className="mt-3">Dealer Stock</h4>
        <div className="icons">
          {/* <ul>
            <li>
              SORT BY: <SearchSortBy />
            </li>
            <li>
              <MdGridView />
            </li>
            <li>
              <FaListUl />
            </li>
          </ul> */}
        </div>
      </div>
      <div className="stock-cards">
        {sortedWavs && sortedWavs.length > 0 && (
          <DealerPageNavPanelCards wavs={sortedWavs} />
        )}
      </div>
    </div>
  );
};

export default DealerPageNavPanelStock;
