import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { AiFillStar } from "react-icons/ai";
import { GiGearStickPattern } from "react-icons/gi";
import { FaRoad, FaWheelchair } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getWav, getWavBySlug } from "../../redux/actions/wavActions";
import { formatWavUrlString } from "../../Helpers/utils";

export default function DealerPageNavPanelCard({ wav }) {
  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let price = 0
  if (Object.entries(wav).length > 0) {
    wav.price_2 = wav.price.replace(/[^0-9]/g, "")
    // console.log(wav)
    price = parseFloat(wav.price_2);
    // console.log(price)

    wav.mileage = wav.mileage.replaceAll(`\"`, "")

  }

  // const price = parseFloat(wav.price);
  console.log(wav, '----WAV')
  return (
    <div
      onClick={() => {
        // dispatch(getWav(wav._id));
        dispatch(getWavBySlug(wav.slug, history));
        // history.push(`/wavs/${wav._id}`, {
        //   wav_id: wav._id
        // });

        // history.push(`/wavs/${formatWavUrlString(wav)}`, {
        //   wav_id: wav._id
        // });  
        
        history.push(`/wavs/${wav.slug}`, {
          wav_id: wav._id
        });

        
      }}
      className="dealer-page-nav-panel-card"
    >
      <Card>
        <CardMedia
          component="img"
          height="160"
          image={
            wav.images
              ? `https://dev.wavcompare.com/uploads/${wav.images && wav.images.length > 0 && wav.images[0].replace("images/", "images/thumbnails/")}`
              : "/assets/toyota.png"
          }
          alt="Paella dish"
        />
        <div className="panel-buttons mt-2">
          <h6>{wav.title}</h6>
          <button className="panel-price-btn">£{price.toLocaleString()}</button>
        </div>
        <CardContent>
          <div className="icon-options">
            <div>
              <span>
                <AiFillStar />
              </span>
              <small style={{ textTransform: "capitalize" }}>
                {wav.condition}
              </small>
            </div>
            <div>
              <span>
                <GiGearStickPattern />
              </span>
              <small>{wav.transmission}</small>
            </div>
          </div>
          <div className="icon-options">
            <div>
              <span>
                <FaRoad />
              </span>
              <small>{wav.mileage} miles</small>
            </div>
            <div>
              <span>
                <FaWheelchair />
              </span>
              <small>{wav.seating}</small>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
