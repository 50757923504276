import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import "./style.css";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import magnifierImage from "../../../images/search.png";


const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;

const EnquiryForm = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required!").email("Email is invalid"),
    name: Yup.string().required("Required!"),
    city: Yup.string().required("Required!"),
    seats: Yup.string().required("Required!"),
    budget: Yup.string().required("Required!"),
    gear_type: Yup.string().required("Required!"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      city: "",
      seats: "",
      budget: "",
      gear_type: "",
      description: ""
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await fetch(`${url}/wav/enquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        resetForm();
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    },
  });





  return (
    <div className="about container">
      <div className="enquiry_form_styling">
        <div className="main_heading_form">
          <h2 className="form_heading_styling" id="wavfinder">
            <span className="first_heading_form">Try Our New WAV </span>{" "}
            <span className="second_heading_form">Finder Tool</span>{" "}
            <span>
              <img src={magnifierImage} alt="Magnifier" className="mgnifier-icon" />
            </span>
          </h2>
          <p>
            Looking for a wheelchair accessible vehicle? Let us do the work for
            you!
          </p>
          <p>
            Simply tell us what vehicle your looking for below and we'll contact
            our network of dealers It's free, fast, and puts you on the road to
            freedom faster.
          </p>
        </div>

        <div className="enquiry_form_styling2">
          <div className="main_heading2_form">
            <h2 className="form_heading_styling">Relax,</h2>
            <h3 className="form_heading_styling">
              Let <span className="decoration_styling">Us</span> Find Your
              Vehicle For You!
            </h3>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    placeholder="Name"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  <span style={{ color: "red", textAlign: "left" }}>
                    {formik.errors.name ? (
                      <div>{formik.errors.name} </div>
                    ) : null}{" "}
                  </span>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="customSelect position-relative">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={formik.handleChange}
                      value={formik.values.seats}
                      name="seats"
                    >
                      <option selected value="">Number of Seats Required?</option>
                      {
                        [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((item, i) => {
                          return (<option value={item} key={i}>{item}</option>)
                        })
                      }

                    </select>
                    <span style={{ color: "red", textAlign: "left" }}>
                      {formik.errors.seats ? (
                        <div>{formik.errors.seats} </div>
                      ) : null}{" "}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <span style={{ color: "red", textAlign: "left" }}>
                    {formik.errors.email ? (
                      <div>{formik.errors.email} </div>
                    ) : null}{" "}
                  </span>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="customSelect position-relative">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={formik.handleChange}
                      value={formik.values.budget}
                      name="budget"
                    >
                      <option selected value="">Your Budget?</option>
                      <option value="£0 - £5,000">£0 - £5,000</option>
                      <option value="£5,000 - £10,000">£5,000 - £10,000</option>
                      <option value="£10,000 - £15,000">£10,000 - £15,000</option>
                      <option value="£15,000 - £20,000">£15,000 - £20,000</option>
                      <option value="£20,000+">£20,000+</option>
                    </select>
                    <span style={{ color: "red", textAlign: "left" }}>
                      {formik.errors.budget ? (
                        <div>{formik.errors.budget} </div>
                      ) : null}{" "}
                    </span>
                  </div>

                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="city"
                    placeholder="City You're Based in"
                    name="city"
                    onChange={formik.handleChange}
                    value={formik.values.city}
                  />
                  <span style={{ color: "red", textAlign: "left" }}>
                    {formik.errors.city ? (
                      <div>{formik.errors.city} </div>
                    ) : null}{" "}
                  </span>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="customSelect position-relative">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={formik.handleChange}
                      value={formik.values.gear_type}
                      name="gear_type"
                    >
                      <option selected value="">Either Manual or Auto?</option>
                      <option value="manual">Manual</option>
                      <option value="auto">Auto</option>
                      <option value="both">Both</option>
                    </select>
                    <span style={{ color: "red", textAlign: "left" }}>
                      {formik.errors.gear_type ? (
                        <div>{formik.errors.gear_type} </div>
                      ) : null}{" "}
                    </span>
                  </div>

                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="textarea_styling">
                    <textarea
                      id="description"
                      class="form-control"
                      name="description"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      placeholder="Any Other Information ie. Make, Model, Wheelchair Dimensions"
                    ></textarea>
                    <span style={{ color: "red", textAlign: "left" }}>
                      {formik.errors.description ? (
                        <div>{formik.errors.description} </div>
                      ) : null}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit_btn_styling">
              <input type="submit" class="form-control" value="Submit" />
            </div>
          </form>
          {success && (
            <div className="alert alert-success mb-3" role="alert">
              Your message has been sent successfully
            </div>
          )}
          {error && (
            <div className="alert alert-danger mb-3" role="alert">
              Your message has not been sent. Please fill all the details.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnquiryForm;
