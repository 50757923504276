import { useState } from "react";
import Accordion from "./Accordion"

const Features = ({ features, selectFeature }) => {

    let featuresArr = [];

    features.forEach((ele) => {
        if (ele.hasOwnProperty("category_id")) {
            let obj = featuresArr.find((em) => em._id == ele.category_id._id);
            if (obj) {
                obj.features_list.push(ele)
            }
            else {
                featuresArr.push({
                    _id: ele.category_id._id,
                    title: ele.category_id.title,
                    description: ele.category_id.description,
                    features_list: [ele]
                })
            }
        }
        else {
            let obj = featuresArr.find((em) => em.title == "others");
            if (obj) {
                obj.features_list.push(ele)
            }
            else {
                featuresArr.push({
                    _id: "testingid",
                    title: "others",
                    description: "testing description",
                    features_list: [ele]
                })
            }
        }
    })

    const [selected, setSelected] = useState(0);

    return (
        <div className="select-features accr">
            <h3 className="main-heading">Select Vehicle Features<p className="f-vehicle">(Choose the features of your vehicle)</p></h3>
            <div className="features accordion">
                {
                    featuresArr.map((ele, index) => {
                        return (
                            <Accordion item={ele} index={index} selected={selected} setSelected={setSelected} key={index} selectFeature={selectFeature} />
                        )
                    })
                }
                {/* {features &&
                    features.length > 0 &&
                    features.sort().map((feature) => {
                        return (
                            <button
                                onClick={() => selectFeature(feature._id)}
                                className={feature.selected ? "feature-selected" : null}
                                id={feature._id}
                                type="button"
                                key={feature._id}
                            >
                                {feature.title}
                            </button>
                        );
                    })} */}
            </div>
        </div>
    )
}

export default Features