import React, { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { mileageFilterOptions } from "../../Helpers/utils";

const mileageOptions = [
  "0-10000",
  "10000-20000",
  "20000-30000",
  "30000-40000",
  "40000-50000",
  "50000-60000",
  "60000-70000",
  "70000-80000",
  "80000-90000",
  "90000-100000",
  "100000-110000",
  "110000-120000",
  "120000-130000",
  "130000-140000",
  "140000-150000",
  "150000-160000",
  "160000-170000",
  "170000-180000",
  "180000-190000",
  "190000-200000",
];
export default function SearchResultsFilter({
  name,
  list,
  value,
  setValue
}) {

  console.log("value----", value)

  const searchedWords = useSelector((state) => state.wavs.searchedWords);

  useEffect(() => {
    setValue("");
  }, [searchedWords]);

  const handleChange = (event) => {
    // console.log("event.target.value", event.target.value)
    setValue(event.target.value);
  };

  return (
    <div>
      <FormControl
        variant="standard"
        id="result-filter"
        sx={{ minWidth: 120, my: "5px" }}
      >
        <InputLabel id="filter-select">{name}</InputLabel>
        {
          name == "MILEAGE" ?
            (
              <Select
                labelId="filter-select"
                id="filter-select"
                value={value}
                onChange={handleChange}
                label="Age"
              >
                <MenuItem value="">All</MenuItem>
                {mileageFilterOptions.map((option) => (
                  <MenuItem id="filter-select-item" key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>


            ) :

            name == "SEATING" ?
              (
                <Select
                  labelId="filter-select"
                  id="filter-select"
                  value={value}
                  onChange={handleChange}
                  label="Age"
                >
                  <MenuItem value="">All</MenuItem>
                  {list && list.map((option, index) => {
                    return typeof option == "object" ?
                      (
                        <MenuItem id="filter-select-item" key={index} value={JSON.stringify(option)}>
                          {`${option.seats} ${option.seats > 1 ? "SEATS" : "SEAT"} + ${option.wheelchairs} ${option.wheelchairs > 1 ? "WHEELCHAIRS" : "WHEELCHAIR"}`}
                        </MenuItem>
                      )
                      :
                      (
                        <MenuItem id="filter-select-item" key={index} value={option}>
                          {option}
                        </MenuItem>
                      )
                  })}
                </Select>


              ) :


              (
                <Select
                  labelId="filter-select"
                  id="filter-select"
                  value={value}
                  onChange={handleChange}
                  label="Age"
                >
                  <MenuItem value="">All</MenuItem>
                  {list && list.map((element, i) => (
                    <MenuItem id="filter-select-item" key={i} value={element}>
                      {element}
                    </MenuItem>
                  ))}
                </Select>
              )
        }
      </FormControl>
    </div>
  );
}
