import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

export default function OverallRating({ name, setOverallRating }) {
  const [value, setValue] = useState(5);
  useEffect(() => {
    setOverallRating(value);
  }, [value]);
  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
      }}
    >
      <Typography sx={{ fontWeight: "bold", mb: 1 }} component="legend">
        {name}
      </Typography>
      <Rating
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
    </Box>
  );
}
