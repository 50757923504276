import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { isNumberKey, poundsformatter } from "../../Helpers/utils";



export default function PriceSlider({ value, setValue,
  priceSliderRange
  // setMinPrice, setMaxPrice
}) {
  const minPriceValue = 0;

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minPriceValue), value[1]]);
      // setMinPrice(value[0]);
      // setMaxPrice(value[1]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minPriceValue)]);
      // setMinPrice(value[0]);
      // setMaxPrice(value[1]);
    }
  };


  const handleMinInputChange = (event) => {
    const numericInput = +event.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
    setValue([numericInput, value[1]]);
  }

  const handleMaxInputChange = (event) => {
    const numericInput = +event.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
    setValue([value[0], numericInput]);
  }


  return (
    <div className="price-slider" style={{ width: "80%", margin: "0 auto" }}>
      <h5
        style={{
          color: "#002e5b",
          textAlign: "left",
          margin: "20px 0px",
          fontSize: "16px",
        }}
      >
        Select Price Range
      </h5>
      <Box sx={{ width: "95%", marginX: "auto" }}>
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          disableSwap
          // max={1000000}
          max={priceSliderRange[1]}
          // min={0}
          min={priceSliderRange[0]}

          step={250}

        />
      </Box>
      {
        (value && value.length) ?
          <div className="price-slider-values">

            <input
              className="price-slider-value"
              type="text"
              value={value[0] !== '' ? poundsformatter.format(Number(value[0])) : ''}
              onChange={handleMinInputChange}
              placeholder="Enter numbers only"
              pattern="[0-9]*"
            />


            <input
              className="price-slider-value"
              type="text"
              value={value[1] !== '' ? poundsformatter.format(Number(value[1])) : ''}
              onChange={handleMaxInputChange}
              placeholder="Enter numbers only"
              pattern="[0-9]*"
            />

          </div>
          : ''
      }
    </div>
  );
}
