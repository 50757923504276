import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './style.css'
import { toast } from "react-toastify";
import { loginDealer, selectComponent, toggleModal } from "../../redux/actions/dealerActions";

const Signin = () => {
    const dispatch = useDispatch();
    const history = useHistory()

    const [show, setShow] = useState(false)
    const [inputs, setInputs] = useState({
        email: "",
        password: ""
    })
    const [loginError, setLoginError] = useState(false)

    const handleInputChange = (e) => {
        setInputs({
            ...inputs, ...{ [e.target.name]: e.target.value }
        })
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        if (inputs.email != '' && inputs.password != '') {
            const loggedWith = await dispatch(
                loginDealer({
                    login: inputs.email,
                    password: inputs.password,
                })
            );
            if (loggedWith.status === "success") {
                toast.success("Sign In Successful")
                loggedWith.id && history.push(`/profile/${loggedWith.id}`);
                dispatch(toggleModal(false))
                // document.body.classList.remove('overflow_hidden');
            } else {
                toast.error("Sign In Failure")
                setLoginError(true)
                setTimeout(() => {
                    setLoginError(false)
                }, 7000)

            }
        }

    };

    return (
        <>
            <h1>Sign In</h1>
            {
                loginError &&
                (
                    <div className='error_msg1 mt-2'>
                        <h6>There's been an error signing you in</h6>
                        <p>Please enter a correct email and password combination to sign in to your account or create a new account</p>
                    </div>
                )
            }

            <p className='my-3 py-2'>Are you a new customer? <button className='comp_button' onClick={() => {
                dispatch(selectComponent("create_account"))
            }} >Create an account here</button></p>
            <form onSubmit={handleLogin}>
                <div className='form-group'>
                    <label>Email Address</label>
                    <input
                        type="email"
                        name="email"
                        value={inputs.email}
                        placeholder="Enter Email"
                        onChange={(e) => handleInputChange(e)}
                    />
                </div>
                <div className='form-group'>
                    <label>Password</label>
                    <div className='position-relative'>
                        <input
                            type={show ? "text" : "password"}
                            name="password"
                            value={inputs.password}
                            placeholder="Enter Password"
                            onChange={(e) => handleInputChange(e)}
                        />
                        <button type="button" className='btn show_btn p-0 bg-transparent' onClick={() => setShow(!show)}>{show ? "HIDE" : "SHOW"}</button>
                    </div>
                </div>
                <button type="submit" className='btn main_btn1 text-white mt-4'>SIGN IN</button>
                <p className='text-center mt-3  '><button className='comp_button' onClick={() => {
                    dispatch(selectComponent("reset_password"))
                }} >Forgot your password?</button></p>
            </form>
        </>
    )
}

export default Signin