import React from 'react';
import { Link } from 'react-router-dom';
import "./index.css"
// import PageNotFound from '../assets/images/PageNotFound';

const NotFoundPage = () => {
    return (
        <div className='fullHeight-section d-flex align-items-center justify-content-center'>
            {/* <img src={PageNotFound} /> */}
            <div className='text-center'>
                <h2 className='title text-center mb-3 text-blue'>404 </h2>
                <h6 className='text-center mb-4'>The page you are looking for may have been removed, <br /> had it's name changed or is temporarily unavailable</h6>
                <div className="btn btn-lg show-all">        <a href={window.location.origin + `/`} onClick={(event) => event.preventDefault()} style={{ textDecoration: "none" }} >

                    <Link to={`/`}  className="text-decoration-none text-white" >FIND YOUR NEXT WAV</Link>
                </a></div>
            </div>
        </div>
    )
};

export default NotFoundPage;