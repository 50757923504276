import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SearchSortBy({ sortValue, setSortValue }) {
  const handleChange = (event) => {
    setSortValue(event.target.value);
  };

  return (
    <div>
      <FormControl
        variant="filled"
        size="small"
        sx={{ m: 2, minWidth: 100, width: "200px" }}
      >
        <InputLabel sx={{ fontSize: "14px", p: 0, m: 0 }} id="sort-select">
          Date: newest first
        </InputLabel>
        <Select
          labelId="sort-select"
          id="sort-select"
          value={sortValue}
          onChange={(e) => setSortValue(e.target.value)}
          style={{ color: "#333", fontSize: "14px" }}
        >
          <MenuItem value="newest">Date: newest first</MenuItem>
          <MenuItem value="oldest">Date: oldest first</MenuItem>
          <MenuItem value="cheapest"> Price: cheapest first</MenuItem>
          <MenuItem value="expensive">Price: the most expensive first</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
