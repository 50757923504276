import React from "react";
import { AiFillCar } from "react-icons/ai";
import { BsCurrencyPound } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import "./style.css";
const BuyOrSale = () => {
  const cookies = new Cookies();
  const user = cookies.get("user");
  const history = useHistory();
  const id = user ? `profile/${user._id}` : "signup";
  return (
    <div className="buyOrSale">
      <div className="buy-wrapper">
        <div className="icon">
          <img src="/assets/searching-car.png" alt="searching-icon" />
        </div>
        {/* <h1>Looking to BUY a WAV?</h1> */}
        <h2>Looking to BUY a WAV?</h2>
        <p>
          We have 100’s of used and new wheelchair cars available to buy from
          expert dealers and private individuals.
        </p>
        <button
          onClick={() => {
            history.push(`/buy`);
          }}
          className="btn buy-or-sale-button btn-lg"
        >
          BROWSE INVENTORY
        </button>
      </div>
      <div className="sell-wrapper">
        <div className="icon">
          <BsCurrencyPound />
        </div>
        {/* <h1>Do you have a WAV to SELL?</h1> */}
        <h2>Do you have a WAV to sell?</h2>
        <p>
          We can put your WAV right in front of people looking to buy – sell it
          quickly and easy with us.
        </p>
        <button
          onClick={() => {
            history.push(`/${id}`);
          }}
          className="btn buy-or-sale-button btn-lg"
        >
          SELL YOUR WAV
        </button>
      </div>
    </div>
  );
};

export default BuyOrSale;
