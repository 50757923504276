import React, { useEffect, useState } from "react";
import { FaPhoneAlt, FaFacebookF, FaTwitter } from "react-icons/fa";
import { MdOutlineEmail, MdOutlineAccessTime } from "react-icons/md";
import { TiSocialYoutubeCircular } from "react-icons/ti";
import "./style.css";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;

const ContactUs = () => {
  const [selectedPanel, setSelectedPanel] = useState("contact-details");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const sendEmail = async () => {
    const emailObj = {
      firstName,
      lastName,
      phoneNumber,
      email,
      subject,
      message,
    };
    try {
      const response = await fetch(`${url}/contact/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailObj),
      });
      if (response.ok) {
        const data = await response.json();
        setFirstName("");
        setLastName("");
        setPhoneNumber("");
        setEmail("");
        setMessage("");
        setSubject("");
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    sendEmail();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="contact-us">
      <div className="contact-us-main">
        <div className="wrapper">
          <div className="text-wrapper">
            <h1>Get in Touch</h1>
            <div className="subtitle">
              Hire or lease a vehicle with wheelchair access – available
              anywhere across the UK and delivered to your door!
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-wrapper mt-5">
        <form onSubmit={handleSubmit} className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label htmlFor="first-name">First Name:</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                className="form-control"
                id="first-name"
                placeholder="Enter your first name..."
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email address:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="form-control"
                id="email"
                placeholder="Enter your email..."
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label htmlFor="last-name">Last Name:</label>
              <input
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                type="text"
                className="form-control"
                id="last-name"
                placeholder="Enter your last name..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone number:</label>
              <input
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                type="text"
                className="form-control"
                id="phone"
                placeholder="Enter your phone number..."
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="form-group">
              <label htmlFor="subject">Subject:</label>
              <input
                type="text"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                className="form-control"
                id="subject"
                placeholder="Enter subject of your message..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Your Message:</label>
              <textarea
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                className="form-control"
                id="message"
              ></textarea>
              <button type="submit">SEND!</button>
            </div>
          </div>
        </form>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="subscribe"
          />
          <label className="form-check-label" htmlFor="subscribe">
            Subscribe and Get latest updates and offers by Email
          </label>
        </div>
        {success && (
          <div className="alert alert-success mt-3" role="alert">
            Your message has been sent successfully
          </div>
        )}
        {error && (
          <div className="alert alert-danger mt-3" role="alert">
            Your message has not been sent. Please fill all the details.
          </div>
        )}
      </div>
      {/* <div className="color-cards">
        <div className="red-card">
          <div>Specific vehicle enquiry?</div>
          <div>
            To enquire about a specific WAV for sale you can
            <a href="#"> contact the seller directly</a> using the contact
            details on any WAV listing page – you can also{" "}
            <a href="#">contact a dealer here</a>.
          </div>
        </div>
        <div className="blue-card">
          <div>Cancel My Advert</div>
          <div>
            You can cancel your advert easily <a href="#"> Click here</a>
          </div>
        </div>
      </div> */}
      {/* <div className="contact-nav-panels mt-5">
        <div className="contact-panel-nav">
          <div
            onClick={() => {
              setSelectedPanel("contact-details");
            }}
            className={
              selectedPanel === "contact-details"
                ? "contact-details contact-panel-selected "
                : "contact-details"
            }
          >
            CONTACT DETAILS
          </div>
          <div
            onClick={() => {
              setSelectedPanel("opening-times");
            }}
            className={
              selectedPanel === "opening-times"
                ? "opening-times contact-panel-selected "
                : "opening-times"
            }
          >
            OPENING TIMES
          </div>
          <div
            onClick={() => {
              setSelectedPanel("social-media");
            }}
            className={
              selectedPanel === "social-media"
                ? "social-media contact-panel-selected "
                : "social-media"
            }
          >
            SOCIAL MEDIA
          </div>
        </div>
        <div className="contact-nav-panels-wrapper">
          {selectedPanel === "contact-details" && (
            <div className="contact-details-wrapper">
              <div className="phone">
                <div className="icon">
                  <FaPhoneAlt />
                </div>
                <div className="text">
                  <div>Phone</div>
                  <a href="#">0800 832 1795</a>
                </div>
              </div>
              <div className="email">
                <div className="icon">
                  <MdOutlineEmail />
                </div>
                <div className="text">
                  <div>Email</div>
                  <a href="#">hello@wavcompare.com</a>
                </div>
              </div>
            </div>
          )}
          {selectedPanel === "opening-times" && (
            <div className="opening-times-wrapper">
              <div className="icon">
                <MdOutlineAccessTime />
              </div>
              <div className="text">
                <h2>Helpline</h2>
                <div className="day-and-hour">
                  <div className="day">Monday-Friday</div>
                  <div className="hour">9:00am - 5.00pm</div>
                </div>
                <div className="day-and-hour">
                  <div className="day">Saturday</div>
                  <div className="hour">Closed</div>
                </div>
                <div className="day-and-hour">
                  <div className="day">Sunday</div>
                  <div className="hour">Closed</div>
                </div>
              </div>
            </div>
          )}

          {selectedPanel === "social-media" && (
            <div className="social-media-wrapper">
              <div className="icon">
                <FaFacebookF />
              </div>
              <div className="icon">
                <FaTwitter />
              </div>
              <div className="icon">
                <TiSocialYoutubeCircular />
              </div>
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default ContactUs;
