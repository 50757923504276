import React, { useState, useEffect } from "react";
import DealersCard from "./DealersCard";
import DealersSortInput from "./DealersSortInput";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { getDealers } from "../../redux/actions/dealerActions";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { BsSearch } from "react-icons/bs";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;
const Dealers = () => {
  const [dealers, setDealers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const dealersFromState = useSelector((state) => state.dealers.list);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getDealers());
  }, []);
  useEffect(() => {
    const sortedDealers = dealersFromState.filter(
      (dealer) => dealer.role === "dealer"
    );
    setDealers(sortedDealers);
  }, [dealersFromState]);

  const handleChange = async (e) => {
    setSearchValue(e.target.value);
    try {
      const response = await fetch(`${url}/dealer/all?postcode=${searchValue}`);
      if (response.ok) {
        const data = await response.json();
        const sortedDealers = data.data.dealers.filter(
          (dealer) => dealer.role === "dealer"
        );
        setDealers(sortedDealers);
      } else {
        const data = await response.json();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="dealers">
      <div className="dealers-header">
        <div className="wrapper">
          <div className="text-wrapper">
            <h1>FIND LOCAL WAV DEALERS</h1>
          </div>
        </div>
      </div>
      {/* <div className="dealers-sort mt-5">
        <div className="card w-100">
          <div className="card-body">
            <div className="dealers-sort-inputs">
              <div className="dealers-sort-input">
                <DealersSortInput name="POSTCODE" />
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    value={searchValue}
                    onChange={handleChange}
                    id="standard-basic"
                    label="POSTCODE"
                    variant="standard"
                  />
                </Box>
              </div>
            </div>
            <div className="btn dealer-sort-btn">
              <BsSearch /> Find Dealer
            </div>
          </div>
        </div>
      </div> */}
      <div className="dealers-cards-wrapper">
        <div className="dealers-cards-title">
          <h5>UK's Leading WAV Dealership</h5>
        </div>
        <div className="dealers-cards">
          {dealers.length >= 0 &&
            dealers.map((dealer) => {
              return (
                <div key={dealer._id}>
                  <DealersCard dealer={dealer} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Dealers;
