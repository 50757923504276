import React, { useState } from "react";
import CustomerRating from "./CustomerRating";
import BuyingRating from "./BuyingRating";
import OverallRating from "./OverallRating";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { getDealer } from "../../redux/actions/dealerActions";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;

const DealerWriteReview = ({ dealer, setSelected }) => {
  const [title, setTitle] = useState("");
  const [review, setReview] = useState("");
  const [customerRating, setCustomerRating] = useState(5);
  const [buyingRating, setBuyingRating] = useState(5);
  const [overallRating, setOverallRating] = useState(5);
  const [recommend, setRecommend] = useState("yes");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const cookies = new Cookies();
  const token = cookies.get("jwt");
  const dispatch = useDispatch();
  const user = cookies.get("user");
  const sendReview = async () => {
    const reviewObj = {
      title,
      review,
      customer_service: customerRating,
      buying_process: buyingRating,
      overall_experience: overallRating,
      recommendation: recommend,
      dealer_id: dealer._id,
    };
    try {
      const response = await fetch(`${url}/review/create`, {
        method: "POST",
        body: JSON.stringify(reviewObj),
        headers: {
          authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSuccess(true);
        setTimeout(() => {
          dispatch(getDealer(dealer._id));
          setSuccess(false);
          setSelected("reviews");
        }, 3000);
      } else {
        const data = await response.json();
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dealer && sendReview();
  };
  return (
    <div className="mb-5">
      <form onSubmit={handleSubmit} className="row">
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder="Example: Great Service, clean facilities"
            />
          </div>
          <div className="form-group">
            <label htmlFor="review">Your Review</label>
            <textarea
              value={review}
              onChange={(e) => setReview(e.target.value)}
              className="form-control"
              id="review"
              rows="7"
            ></textarea>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              I am not a dealer, and I am not employed by a dealership.
            </label>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 ">
          <CustomerRating
            setCustomerRating={setCustomerRating}
            name="Customer Service"
            id="customer"
          />
          <BuyingRating
            setBuyingRating={setBuyingRating}
            name="Buying Process"
            id="buying"
          />
          <OverallRating
            setOverallRating={setOverallRating}
            name="Overall Experience"
            id="overall"
          />
          <div className="recommend mt-4">
            <div>Would you recomment this dealer?</div>
            <div className="inputs">
              <div className="input">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  onChange={() => setRecommend("yes")}
                  id="exampleRadios1"
                  value="option1"
                />
                <label htmlFor="exampleRadios1">Yes</label>
              </div>
              <div className="input">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  onChange={() => setRecommend("no")}
                  value="option2"
                />
                <label htmlFor="exampleRadios2">No</label>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Submit Review
        </button>
      </form>
      {success && (
        <div className="alert alert-success mt-3" role="alert">
          Review has been created successfully
        </div>
      )}
      {error && (
        <div className="alert alert-danger mt-3" role="alert">
          Could not post the review. Something went wrong. Please fill all the
          required fields.
        </div>
      )}
    </div>
  );
};

export default DealerWriteReview;
