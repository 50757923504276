import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { useParams, useHistory, useLocation } from "react-router-dom";

// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWav, getWavBySlug } from "../../redux/actions/wavActions";
import { FaTelegramPlane } from "react-icons/fa";
import "./style.scss";
// import { useLocation } from "react-router-dom";
import { formatWavUrlString } from "../../Helpers/utils";
// import DocumentMeta from "react-document-meta"
import { Helmet } from "react-helmet";
import { RotatingLines } from "react-loader-spinner";
import NotFoundPage from "../NotFoundPage";

const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;
const ProductPage = () => {
  const [wav, setWav] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();

  // console.log("location--->", location)

  const params = useParams();
  const dispatch = useDispatch();
  const selectedWavFromState = useSelector((state) => state.wavs.selected);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("selectedWavFromState.id", selectedWavFromState.slug, params.id);
  // console.log();

  // useEffect(() => {
  //   dispatch(getWavBySlug(params.id, history));
  // }, [params.id]);

  // console.log("location.state.wav_id", location?.state?.wav_id)
  // useEffect(() => {

  //   if (location && location.state && location.state.wav_id) {
  //     dispatch(getWav(location.state.wav_id));
  //   }
  // }, [location]);
  // useEffect(() => {
  //   async function getData() {
  //     setIsLoading(true);
  //     try {
  //       const res = await dispatch(getWav(selectedWavFromState?._id));
  //       console.log('res======>', res)
  //       if (res?.success) {
  //         setIsLoading(false);
  //         // setWav(res?.data?.wav)
  //       } else {
  //         // Handle error condition here
  //         setIsLoading(false); // Set loading to false
  //         // You can also set an error state here if needed
  //         // setErrorState(true);
  //         console.error("Error fetching data:", res?.error);
  //       }
  //     } catch (error) {
  //       // Handle any errors occurred during API call
  //       setIsLoading(false); // Set loading to false
  //       // Set error state if needed
  //       // setErrorState(true);
  //       console.error("Error fetching data:", error);
  //     }
  //   }
  //   getData();
  // }, [dispatch]);

  useEffect(() => {
    getWavData();
  }, [params.id]);

  const getWavData = async () => {
    setIsLoading(true);
    try {
      const res = await dispatch(getWavBySlug(params?.id));

      if (res?.success) {
        setIsLoading(false);
        setWav(res?.data?.wav);
      } else {
        setIsLoading(false);
        console.error("Error fetching data:", res?.error);
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  const sendEmail = async () => {
    const emailObj = {
      name,
      email,
      phone,
      message,
      subject,
      toEmail: wav && wav.owner && wav.owner.email,
      pageUrl: window.location.href,
    };
    try {
      const response = await fetch(`${url}/dealer/contact/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailObj),
      });
      if (response.ok) {
        const data = await response.json();
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");

        setSubject("");
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } else {
        const data = await response.json();
        console.log("error: ", data);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    wav && wav.owner && wav.owner.email && sendEmail();
  };

  console.log("WAV======>", wav);
  // const meta = {
  //   title: (wav && Object.entries(wav).length > 0) ?`${wav.make} ${wav.models} ${wav.engine} - ${wav.doors} - ${wav.entrance} WAV`: "",
  //   description: (wav && Object.entries(wav).length > 0) ? `This ${wav.transmission} ${wav.wav_type} ${wav.make} ${wav.models} has room for ${wav.seating}. ${wav.mileage} miles. All yours for £${wav.price.replace(/[^0-9]/g, "")}.`: "",
  //   viewport: "width=device-width,initial-scale=1",
  //   canonical: (wav && Object.entries(wav).length > 0)? `http://localhost:3000/wavs/${formatWavUrlString(wav)}`: "",
  //   meta: {
  //     charset: "utf-8",
  //     name: {
  //       keywords: "react,meta,document,html,tags"
  //     }
  //   }
  // };

  // console.log("meta", meta)
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />

        <title>
          {wav && Object.entries(wav).length > 0
            ? `${wav.make} ${wav.models} ${wav.engine} - ${wav.doors} - ${wav.entrance} WAV`
            : ""}
        </title>

        <meta
          name="description"
          content={
            wav && Object.entries(wav).length > 0
              ? `This ${wav.transmission} ${wav.wav_type} ${wav.make} ${wav.models
              } has room for ${wav.seating}. ${wav.mileage ? wav.mileage.replaceAll(`\"`, "") : ""
              } miles. All yours for £${wav.price.replace(/[^0-9]/g, "")}.`
              : ""
          }
        />

        {/* <link rel="canonical" href={(wav && Object.entries(wav).length > 0) ? `${window.location.origin}/wavs/${formatWavUrlString(wav)}` : ""} /> */}
        <link
          rel="canonical"
          href={
            wav && Object.entries(wav).length > 0
              ? `${window.location.origin}/wavs/${wav.slug}`
              : ""
          }
        />
      </Helmet>
      {
        isLoading ? (
          <div id="wrapper">
            <div id="info">
              <RotatingLines
                height="96"
                width="96"
                strokeWidth="5"
                strokeColor="#004f63"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          </div>
        ) :
          !wav?.body ? (<NotFoundPage />) : (
            <div className="product-page">
              <div className="product-nav">
                {/* <h1>FIND & COMPARE OVER 500 WAVS FOR SALE</h1> */}
              </div>
              <div className="product-wrapper">
                <div className="product-main-content">
                  {wav && <ProductCard wav={wav} />}
                </div>
                <div className="product-sidebar">
                  <div className="product-sidebar-header">
                    <h1>
                      <span>
                        <FaTelegramPlane />
                      </span>
                      CONTACT DEALER
                    </h1>
                  </div>
                  <div className="product-sidebar-main">
                    <div className="product-sidebar-main-company">
                      <img
                        src={
                          wav && wav.owner
                            ? `https://dev.wavcompare.com/uploads/${wav.owner.logo}`
                            : "/assets/user.jpg"
                        }
                        alt="logo"
                      />
                      <h5>{wav && wav.owner && wav.owner.name}</h5>
                    </div>
                    <div className="product-sidebar-main-contact-details">
                      {/* <div className="product-sidebar-main-contact-detail">
                      <div className="icon">
                        <BsFillTelephoneFill />
                      </div>
                      <div className="normal-text">
                        {wav && wav.owner && wav.owner.phone_number}
                      </div>
                    </div> */}
                      <div className="product-sidebar-main-contact-detail">
                        <div className="icon">
                          <HiLocationMarker />
                        </div>
                        <div className="normal-text">
                          {wav && wav.owner && wav.owner.address}
                        </div>
                      </div>
                      <div className="product-sidebar-main-contact-details-inputs">
                        <h3>Drop us a line</h3>
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <input
                              type="text"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                              className="form-control"
                              id="name"
                              placeholder="Name"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              className="form-control"
                              id="email"
                              placeholder="Email Address"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              value={phone}
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                              className="form-control"
                              id="phone"
                              placeholder="Phone Number"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              value={subject}
                              onChange={(e) => {
                                setSubject(e.target.value);
                              }}
                              className="form-control"
                              id="subject"
                              placeholder="Subject of your message"
                            />
                          </div>
                          <div className="form-group">
                            <textarea
                              value={message}
                              onChange={(e) => {
                                setMessage(e.target.value);
                              }}
                              className="form-control"
                              id="message"
                              placeholder="Message"
                              rows="3"
                            ></textarea>
                          </div>
                          <div className="form-check d-flex align-items-center">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="defaultCheck1"
                            >
                              <small style={{ fontSize: "10px", marginLeft: "10px" }}>
                                I accept the terms of Privacy Policy. <br /> Privacy
                                Policy can be found here.
                              </small>
                            </label>
                          </div>
                          <div className="form-button mt-4">
                            <button type="submit" className="btn ">
                              Send
                            </button>
                          </div>
                          {success && (
                            <div className="alert alert-success mt-3" role="alert">
                              Your message has been sent successfully
                            </div>
                          )}
                          {error && (
                            <div className="alert alert-danger mt-3" role="alert">
                              Your message has not been sent. Please fill all the
                              details.
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                    <ul></ul>
                  </div>
                </div>
              </div>
            </div>
          )
      }
    </>
  );
};

export default ProductPage;
