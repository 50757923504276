import MainNavbar from "../Components/Navbar/MainNavbar"
import Footer from "../Components/Footer/Footer"


const Layout = ({ children }) => {
    return (
        <>
            <MainNavbar />
            {children}
            <Footer />
        </>
    )
}

export default Layout;