import React from "react";

const HelpAndAdviceDecisions = () => {
  return (
    <div className="help-and-advice-decisions">
      <div className="container">
        <h2>
          Other decisions that you must make about your Wheelchair Accessible
          Vehicle
        </h2>
        <div className="subtitle">
          Whether you are going to buy a vehicle that is already converted or
          have a custom conversion done there are several basic things to
          consider when choosing a suitable WAV
        </div>
        <div className="types-card">
          <div className="car-type">
            <div className="car-img">
              <img src="assets/ramp.png" alt="car ramp" />
            </div>
            <div className="car-card">
              <h3> Ramp or Lift </h3>
              <p>
                It’s always worth considering whether you would need a manual
                ramp or an electric tail lift. Although wheelchair chair
                accessible vehicles with ramps are made to be lightweight and
                simple to open/lift, it may be more suitable for some to have an
                electric opening ramp or tail lift that opens via a remote.
              </p>
            </div>
          </div>
          <div className="car-type">
            <div className="car-img">
              <img src="assets/side-enterance.png" alt="car side-enterance" />
            </div>
            <div className="car-card">
              <h3> Side or Rear entrance</h3>
              <p>
                Rear entrance WAVs are more common than side entrance ones. Both
                have advantages and disadvantages. Rear entry gives you space
                behind the vehicle to deploy the ramp or lift for access this is
                good for bay parking but when parallel parking there is always
                the possibly someone else has parked close to the rear. Side
                entry is usually more desirable able and is good for when
                parking on a large drive but isn’t always suitable when parallel
                parking as the ramp usually cannot be used on the pavement,
                meaning the wheelchair user would need to enter the side on the
                road, where there could be could be oncoming traffic.
              </p>
            </div>
          </div>
        </div>
        <div className="row smaller">
          <div className="car-type mt-5">
            <div className="car-img">
              <img src="assets/fuel-tank.png" alt="car fuel-tank" />
            </div>
            <div className="car-card">
              <h3> Fuel tank size</h3>
              <p>
                Frequently the floor of the vehicle is lowered to make the ramp
                access easier. This means that sometimes the size of the fuel
                tank is reduced. If you live a long way from a petrol station
                this could be a problem.
              </p>
            </div>
          </div>
          <div className="car-type mt-5">
            <div className="car-img">
              <img
                src="assets/internal-transfer.png"
                alt="car internal transfer"
              />
            </div>
            <div className="car-card">
              <h3> Internal transfer WAVS</h3>
              <p>
                Internal transfer WAVS are modified to allow the wheelchair user
                to pass easily from the wheelchair into the driving seat. They
                then stow the chair in the vehicle.
              </p>
            </div>
          </div>
          <div className="car-type mt-5">
            <div className="car-img wheelchair">
              <img
                className="wheelchair"
                src="assets/drive-from-wheelchair.png"
                alt="car wheelchair"
              />
            </div>
            <div className="car-card">
              <h3>Drive from wheelchair</h3>
              <p>
                Drive from wheelchair WAVs allow the user to control the vehicle
                directly from their wheelchair which enters into the driving
                position through either a side or rear door and locks down
                behind the steering wheel.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="headroom mt-5">
          <div className="car-img mb-3">
            <img src="assets/headroom.png" alt="car headroom" />
          </div>
          <div className="headroom-card">
            <h3>Headroom</h3>
            <div className="subtitle">Have you checked the measurements?</div>
            <p>
              We recommend to measure the wheelchair user whilst in the chair
            </p>
            <ul className="sizes">
              <li>A. Height</li>
              <li>B. Length</li>
              <li>C. Width</li>
            </ul>
            <img src="assets/headroom-human.jpg" alt="human" />
            <div className="paragraphs">
              <div>
                You can then check the measurements with the dealer/seller as
                photos don’t show an accurate size of the vehicle, always allow
                some extra space.
              </div>
              <div>
                Majority of manual/push-wheelchairs fit into wheelchair
                accessible vehicles but It is always best to check all
                measurements. Electric wheelchairs tend to be larger than manual
                wheelchairs.
              </div>
              <div>
                You will need to check before buying that the vehicles ramp/lift
                weight capacity is suitable.
              </div>
              <div>The Vehicle measurements we recommend to measure are</div>
            </div>
            <ul className="measurements">
              <li> ramp/lift width</li>
              <li>internal wheelchair position width</li>
              <li>Entry height and internal height</li>
              <li>Wheelchair position length</li>
              <li>Ramp/lift weight capacity</li>
            </ul>
            <div className="paragraphs">
              <div>
                This is to ensure all points of entry and internal space are
                suitable for the wheelchair passenger to enter and travel
                comfortably.
              </div>
              <div>
                If the vehicle is a drive from it is also import to check what
                locking system is used for the wheelchair to be secured into
                place as they aren’t universal. Locking systems will only work
                if compatible with the wheelchair.
              </div>
              <div>
                Some vehicles are adapted so that the wheelchair user can
                transfer via swivel seat from their wheelchair to driver seat.
              </div>
              <div>
                <strong> Please note:</strong> We always recommend that you to
                view a wheelchair accessible vehicle in person before
                purchasing, whether you have a manual or electric wheelchair you
                should always check the vehicle is suitable in person..
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HelpAndAdviceDecisions;
