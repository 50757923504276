import React from "react";

const ProfileHeader = () => {
  return (
    <div className="profile-header">
      <div className="wrapper">
        <div className="text-wrapper">
          <h1>Create your </h1>
          <div className="subtitle">
            The Ultimate Guide to Wheelchair Accessible Vehicles
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
