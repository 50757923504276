import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleModal, forgotPassword, selectComponent } from '../../redux/actions/dealerActions';
import './style.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';


const Resetpassword = () => {

    const dispatch = useDispatch();
    const [signupError, setSignupError] = useState("")
    const [success, setSuccess] = useState(false)

    const validationSchema = Yup.object().shape({
        email: Yup.string().required("Email is required").email("Email is invalid"),
    });


    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema,
        // validateOnChange: false,
        // validateOnBlur: false,
        onSubmit: (data) => {
            resetPassword(data)
        },
    });



    const resetPassword = async (data) => {
        try {
            const signUpWith = await dispatch(
                forgotPassword({
                    email: data.email,
                })
            );

            if (signUpWith.status === "success") {
                toast.success("Email sent successfully")
                setSuccess(true)
                // dispatch(selectComponent("check_email"))
            } else {
                setSignupError(signUpWith.error);
                setTimeout(() => {
                    setSignupError("");
                }, 5000)
            }

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            {
                !success ?
                    <>
                        <h1>Reset Password</h1>
                        {/* error msg */}
                        {signupError && <div className='error_msg1 mt-2'>
                            <h6>Sorry, we don't recognise that email</h6>
                            <p>{signupError}</p>
                        </div>}
                        {/* error msg */}
                        <p className='my-3 py-2'>Are you a new customer? <button className='comp_button' onClick={() => {
                            dispatch(selectComponent("create_account"))
                        }}  >Create an account here</button></p>

                        <form className='pl-3' onSubmit={formik.handleSubmit}>
                            <div className='form-group'>
                                <label>Email Address</label>
                                <input
                                    name="email"
                                    type="email"
                                    placeholder="Enter Email"
                                    className={
                                        'form-control' +
                                        (formik.errors.email && formik.touched.email
                                            ? ' is-invalid'
                                            : '')
                                    }
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                                <div className="invalid-feedback">
                                    {formik.errors.email && formik.touched.email
                                        ? formik.errors.email
                                        : null}
                                </div>
                            </div>
                            <button type="submit" className='btn main_btn1 text-white mt-2'>Reset Password</button>
                        </form>
                    </>
                    :
                    <>
                        <h1>Check your email</h1>
                        <form className='pl-3' onSubmit={formik.handleSubmit}>

                            <div className='mt-4 mb-3 black_text px-3'>
                                <p className='mb-2'>A link to reset your password has been sent to your email.</p>
                                {/* <p className='mb-2'>The link will expire in 24 Hours.</p> */}
                                <p className='mb-2'>Please check your spam/junk folder if you haven't received anything through!</p>
                            </div>
                            <p className='pl-3'><button className='comp_button' type="submit"  >Resend password reset email</button></p>
                            <p className='pl-3 mt-2'><button type="button" className='comp_button' onClick={() => setSuccess(false)} >Use a different email address</button></p>
                        </form >
                        {/* EMAIL RESENT! */}
                        {/* <h6 className='mail_sent_text pl-3 mt-4'>EMAIL RESENT!</h6> */}
                        {/* EMAIL RESENT! */}
                    </>
            }

        </>
    )
}
export default Resetpassword;