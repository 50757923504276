import React, { useState, useEffect } from "react";
import About from "./About/About";
import BuyOrSale from "./BuyOrSale/BuyOrSale";
import Featured from "./Featured/Featured";
import MainLayout from "./MainLayout/MainLayout";
import Showcase from "./Showcase/Showcase";
import { useDispatch, useSelector } from "react-redux";
import { getWavs } from "../../redux/actions/wavActions";
import { getDealers } from "../../redux/actions/dealerActions";
import EnquiryForm from "./Enquiry/Form";

const HomePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getWavs());
  }, []);

  return (
    <div>
      <div className="main-layout">
        <MainLayout />
      </div>
      <div className="section">
        {/* <About /> */}
        <EnquiryForm />
        <Showcase />
        <BuyOrSale />
        {/* <Featured /> */}
      </div>
    </div>
  );
};

export default HomePage;
