import React, { useEffect } from "react";
import "./style.css";
const CancelAds = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="cancel-ads">
      <div className="cancel-ads-header">
        <div className="wrapper">
          <div className="text-wrapper">
            <h1>CANCEL YOUR ADVERT</h1>
            <div className="subtitle">
              If you sold your vehicle you can cancel your advert easily
            </div>
          </div>
        </div>
      </div>
      <div className="advertise">
        <h6>Advertise quickly to 100’s of buyers.</h6>
        <div>
          We are the UK’s only dedicated platform to buy and sell wheelchair
          accessible vehicles and make it easy for anyone to sell a WAV quickly.
        </div>
        <div>
          100’s of people visit us everyday looking to buy only WAVs – so if you
          have one for sale start below and advertise today!
        </div>
        <div>3 easy steps to selling your WAV:</div>
        <ol>
          <li>Enter your email below and get 7 days free</li>
          <li>Complete advert listing and upload photos</li>
          <li>Publish live and connect directly with buyers</li>
        </ol>
      </div>
      <div className="contact-form">
        <form>
          <div class="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              className="form-control"
              id="name"
              aria-describedby="name"
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label htmlFor="email">Email address:</label>
            <input
              type="email"
              className="form-control"
              id="email"
              aria-describedby="emailHelp"
              placeholder=""
            />
            <small id="emailHelp" className="form-text text-muted">
              Ensure this is the same email address you used at sign up.
            </small>
          </div>
          <div class="form-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              className="form-control"
              id="username"
              aria-describedby="userName"
              placeholder=""
            />
            <small id="userName" className="form-text text-muted">
              Username you created to login to WAV Compare.
            </small>
          </div>
          <div class="form-group">
            <label htmlFor="stock-id">Stock ID:</label>
            <input
              type="text"
              className="form-control"
              id="email"
              aria-describedby="stockID"
              placeholder=""
            />
            <small id="stockId" className="form-text text-muted">
              You'll find this on your listing under WAV details.
            </small>
          </div>
          <div>
            Please remove my advert and cancel any future payment. (48 hours
            notice required).
          </div>

          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              Yes, I confirm
            </label>
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default CancelAds;
