import React, { useEffect, useState, useMemo } from "react";
import SearchCard from "./SearchCard";
import SearchCardGridView from "./SearchCardGridView";
import SearchSortBy from "./SearchSortBy";
import { MdGridView } from "react-icons/md";
import { FaListUl } from "react-icons/fa";
import Pagination from "./Pagination";
import { useDispatch, useSelector } from "react-redux";
import { searchWavs } from "../../redux/actions/wavActions.js";
import { CLEAR_FILTERS } from "../../redux/constants/constants";
import { PEUGEUTSVG, VAUXHALLSVG, RENAULTSVG, FIATSVG } from "../../Components/Icon/Icon"
let PageSize = 10;
const SearchMainContent = ({ wavs, length, searchValue, refToTop }) => {
  const [grid, setGrid] = useState(false);
  const [sortValue, setSortValue] = useState("newest");
  const [sortedWavs, setSortedWavs] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [vauxhall, setVauxhall] = useState(false);
  const [fiat, setFiat] = useState(false);
  const [peugeot, setPeugeot] = useState(false);
  const [renault, setRenault] = useState(false);
  const dispatch = useDispatch();
  const searchedWords = useSelector((state) => state.wavs.searchedWords);

  const searchKeyWords = searchedWords.filter((word) => word !== null);

  useEffect(() => {
    if (wavs && wavs.length && wavs.length > 0 && sortValue === "newest") {
      const array = [...wavs];
      setSortedWavs(
        array.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      );
    }
    if (wavs && wavs.length && wavs.length > 0 && sortValue === "oldest") {
      const array = [...wavs];
      setSortedWavs(
        array.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
      );
    }
    if (wavs && wavs.length && wavs.length > 0 && sortValue === "cheapest") {
      const array = [...wavs];
      setSortedWavs(
        array.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
      );
    }
    if (wavs && wavs.length && wavs.length > 0 && sortValue === "expensive") {
      const array = [...wavs];
      setSortedWavs(
        array.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
      );
    }
  }, [sortValue]);
  const handleQuickLinks = (link) => {
    const name = link.name.toLowerCase();
    const value = link.value;
    if (name === "body") {
      dispatch(searchWavs(value, "", "", "", "", "", "", "", "", "", "", ""));
    }
    if (name === "make") {
      dispatch(searchWavs("", value, "", "", "", "", "", "", "", "", "", ""));
    }
    if (name === "model") {
      dispatch(searchWavs("", "", value, "", "", "", "", "", "", "", "", ""));
    }
    if (name === "mileage") {
      dispatch(searchWavs("", "", "", value, "", "", "", "", "", "", "", ""));
    }
    if (name === "size") {
      dispatch(searchWavs("", "", "", "", value, "", "", "", "", "", "", ""));
    }
    if (name === "transmission") {
      dispatch(searchWavs("", "", "", "", "", value, "", "", "", "", "", ""));
    }
    if (name === "seating") {
      dispatch(searchWavs("", "", "", "", "", "", value, "", "", "", "", ""));
    }
    if (name === "entrance") {
      dispatch(searchWavs("", "", "", "", "", "", "", value, "", "", "", ""));
    }
    if (name === "doors") {
      dispatch(searchWavs("", "", "", "", "", "", "", "", value, "", "", ""));
    }
    if (name === "year") {
      dispatch(searchWavs("", "", "", "", "", "", "", "", "", value, "", ""));
    }
    if (name === "price") {
      dispatch(searchWavs("", "", "", "", "", "", "", "", "", "", value, ""));
    }
    if (name === "wavType") {
      dispatch(searchWavs("", "", "", "", "", "", "", "", "", "", "", value));
    }
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return (
      sortedWavs &&
      sortedWavs.length > 0 &&
      sortedWavs.slice(firstPageIndex, lastPageIndex)
    );
  }, [currentPage, sortedWavs]);

  useEffect(() => {
    setSortValue("newest");
    setSortedWavs(wavs);
    setCurrentPage(1);
  }, []);
  useEffect(() => {
    setSortValue("newest");
    setSortedWavs(wavs);
    setCurrentPage(1);
  }, [wavs]);


  const onHandleActive = (brand) => {
    try {
      switch (brand) {
        case "Vauxhall":
          setVauxhall(true);
          setFiat(false);
          setPeugeot(false);
          setRenault(false)
          break;
        case "Fiat":
          setVauxhall(false);
          setFiat(true);
          setPeugeot(false);
          setRenault(false)
          break;
        case "Peugeot":
          setVauxhall(false);
          setFiat(false);
          setPeugeot(true);
          setRenault(false)
          break;
        case "Renault":
          setVauxhall(false);
          setFiat(false);
          setPeugeot(false);
          setRenault(true);
          break;
      }
    } catch (error) {

    }
  }
  return (
    <div>
      <div className="quick-links">
        <div className="card w-80 mt-4">
          <div className="card-body">
            <h5 className="card-title">QUICK LINKS</h5>
            <p className="card-text">
              {/* {quickLinks[0]} | UNDER 10,000 MILES | 1 CHAIR + 3 PASSENGERS */}
              {searchKeyWords.map((link) => (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    handleQuickLinks(link);
                  }}
                  style={{ cursor: "pointer" }}
                  key={link.value}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    {link.name}:
                  </span>
                  {link.value}
                </span>
              ))}
            </p>
            <div className="card-images">

              <div className={vauxhall ? "img-fluid citroen" : "img-fluid"} onClick={() => { dispatch(searchWavs("", "Vauxhall")); onHandleActive("Vauxhall") }}>
                <VAUXHALLSVG vauxhall={vauxhall} />
              </div>
              <div className={fiat ? "img-fluid citroen" : "img-fluid"} onClick={() => { dispatch(searchWavs("", "Fiat"));; onHandleActive("Fiat") }}>
                <FIATSVG fiat={fiat} />
              </div>
              <div className={peugeot ? "img-fluid citroen" : "img-fluid"} onClick={() => { dispatch(searchWavs("", "Peugeot"));; onHandleActive("Peugeot") }}>
                <PEUGEUTSVG peugeot={peugeot} />
              </div>

              <div className={renault ? "img-fluid citroen" : "img-fluid"} onClick={() => { dispatch(searchWavs("", "Renault"));; onHandleActive("Renault") }}>
                <RENAULTSVG renault={renault} />
              </div>
            </div>
            <div className="card-footer-wrapper">
              <div className="matches">
                {length > 0 ? length : 0} Matches
                <span
                  onClick={() => {
                    dispatch(searchWavs(""));
                    dispatch({ type: CLEAR_FILTERS });
                  }}
                >
                  Clear all the filters
                </span>
              </div>
              <div className="card-actions">
                <ul>
                  <li className="d-flex align-items-center sort-by">
                    <div> SORT BY:</div>
                    <div>
                      <SearchSortBy
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                      />
                    </div>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="mr-2" onClick={() => setGrid(true)}>
                      <MdGridView />
                    </div>
                    <div onClick={() => setGrid(false)}>
                      <FaListUl />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={grid ? "search-cards row" : "search-cards"}>
        {currentTableData &&
          currentTableData.length > 0 &&
          currentTableData.map((wav) => {
            return grid ? (
              <div className="col-sm-12 col-md-6 col-lg-4 mt-3" key={wav._id}>
                <SearchCardGridView wav={wav} />
              </div>
            ) : (
              <div key={wav._id}>
                <SearchCard wav={wav} />
              </div>
            );
          })}
      </div>
      <div className="mt-4">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={wavs.length}
          pageSize={PageSize}
          onPageChange={(page) => {
            refToTop && refToTop.current && refToTop.current.scrollIntoView({ behavior: 'smooth' })
            setCurrentPage(page)
          }}
        />
      </div>
    </div>
  );
};

export default SearchMainContent;


{/* <img
                height="70px"
                className={vauxhall ? "img-fluid citroen" : "img-fluid"}
                // src="/assets/mono.png"
                src="/assets/brand/VAUXHALL.svg"
                alt="logo"
                onClick={() => { dispatch(searchWavs("", "Vauxhall")); onHandleActive("Vauxhall") }}
              /> */}



{/* <img
                height="70px"
                className={fiat ? "img-fluid citroen" : "img-fluid"}
                src="/assets/fiat.png"
                // src="/assets/fiat.png"
                alt="logo"
                onClick={() => { dispatch(searchWavs("", "Fiat"));; onHandleActive("Fiat") }}
              /> */}




{/* <img
                height="70px"
                className={peugeot ? "img-fluid citroen" : "img-fluid"}
                // src="/assets/peugeot1.png"
                src="/assets/brand/PEUGEUT.svg"
                alt="logo"
                onClick={() => { dispatch(searchWavs("", "Peugeot"));; onHandleActive("Peugeot") }}
              /> */}
{/* <img
                height="70px"
                className={renault ? "img-fluid citroen" : "img-fluid"}
                // src="/assets/citroen1.png"
                src="/assets/brand/RENAULT.svg"
                alt="logo"
                onClick={() => { dispatch(searchWavs("", "Renault"));; onHandleActive("Renault") }}
              /> */}