import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutDealer } from "../../redux/actions/dealerActions";
import AccountInformation from "./AccountInformation";
import PaymentMethod from "./PaymentMethod";
import ProfileHeader from "./ProfileHeader";
import "./style.css";
import YourListing from "./YourListing";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { getDealer } from "../../redux/actions/dealerActions";
import { useHistory, useParams } from "react-router-dom";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';

const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;

const Profile = () => {
  const dealerFromState = useSelector((state) => state.dealers.selected);
  console.log("dealerFromState---", dealerFromState)
  const [selected, setSelected] = useState("your-listings");
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  const [dealerRole, setDealerRole] = useState();
  const [dealer, setDealer] = useState({});
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const user = cookies.get("user");
  const params = useParams();
  const id = params.id;
  const history = useHistory()

  const getUser = async () => {
    try {
      const response = await fetch(`${url}/dealer/${id}`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();
        const { dealer } = data.data;
        setDealerRole(dealer.role);
        setDealer(dealer);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUser();
    dispatch(getDealer(user._id));
    dealerFromState &&
      dealerFromState.role &&
      setDealerRole(dealerFromState.role);
  }, []);
  useEffect(() => {
    getUser();
  }, [id]);
  return (
    <div id="profile">
      <ProfileHeader />
      <div className="profile-main">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-2 dashboard-side ">
            <div className="dashboard">
              <h3 className="dashboard">Dashboard</h3>
            </div>
            <div className="dashboard-side-items">
              {dealerRole !== "" && dealerRole === "user" && (
                <div
                  onClick={() => setSelected("become-dealer")}
                  className={
                    selected === "become-dealer"
                      ? "dashboard-side-selected"
                      : ""
                  }
                >
                  BECOME A DEALER
                </div>
              )}
              <div
                className={
                  selected === "your-listings" ? "dashboard-side-selected" : ""
                }
                onClick={() => {
                  setSelected("your-listings");
                  setEditing(false);
                  setAdding(false);
                }}
              >
                YOUR LISTINGS
              </div>
              <div
                className={
                  selected === "payment-methods"
                    ? "dashboard-side-selected"
                    : ""
                }
                onClick={() => setSelected("payment-methods")}
              >
                PAYMENT HISTORY
              </div>
              <div
                className={
                  selected === "account-information"
                    ? "dashboard-side-selected"
                    : ""
                }
                onClick={() => setSelected("account-information")}
              >
                ACCOUNT INFORMATION
              </div>
              <div onClick={() => {
                // window.FB.logout();
                dealerFromState.loginMethod == 2 && googleLogout();
                // dealerFromState.loginMethod == 3 && window.FB.logout();
                dispatch(logoutDealer())
                history.push("/")
              }}>SIGN OUT</div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-10 dashboard-main">
            {selected === "become-dealer" && (
              <div className="become-dealer">
                <AccountInformation
                  dealerRole={dealerRole}
                  setDealerRole={setDealerRole}
                  type="dealer"
                />
              </div>
            )}
            {selected === "your-listings" && (
              <div className="your-listings">
                <YourListing
                  editing={editing}
                  adding={adding}
                  dealer={dealer}
                  getUser={getUser}
                  setAdding={setAdding}
                  setEditing={setEditing}
                />
              </div>
            )}
            {selected === "payment-methods" && (
              <div className="payment-methods">
                <PaymentMethod />
              </div>
            )}
            {selected === "account-information" && (
              <div className="account-information">
                <AccountInformation
                  type={dealerRole === "dealer" ? "dealer" : "normal"}
                  dealerRole={dealerRole}
                  setDealerRole={setDealerRole}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
