import React from "react";

const ContactUs = ({ history, setMenuOpen }) => {
  return (
    <div className="seller">
      <div className="text">
        <h1 className="longer">Contact Us</h1>
        <h4 className="longer">
          If you have any questions or would like to join us and sell your WAV
          contact us below.
        </h4>
        <h4 className="contact">
          To enquire about a specific WAV for sale you can contact the seller
          directly using the contact details on any WAV listing page – you can
          also contact a dealer.
        </h4>
        <button
          onClick={() => {
            history.push("/contact-us");
            setMenuOpen(false);
          }}
        >
          Click Here
        </button>
      </div>
      <div className="img">
        <img src="/assets/Contact-Us.jpg" alt="seller" className="img-fluid" />
      </div>
    </div>
  );
};

export default ContactUs;
